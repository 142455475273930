import { inject, Injectable } from '@angular/core';
import { ProvisionRest } from './provision.rest';
import { ProvisionMapper } from '@shared/api/provision/provision.mapper';
import { ProvisionGetDTO } from './provision.dto';
import { getCleanObject } from '../../functions';
import { Provision } from '@shared/api/provision/provision.types';
import { DataLeaf } from '../base/composite-and-strategies';

@Injectable({ providedIn: 'root' })
export class ProvisionLeaf extends DataLeaf<Provision, { getModel: ProvisionGetDTO }> {
  protected override access = inject(ProvisionRest);
  protected override mapping = inject(ProvisionMapper);

  override patch = (provision: Partial<Provision>) => {
    const form = new FormData();
    form.append('bereitstellung', JSON.stringify(getCleanObject(this.mapping.toDTO(provision))));
    return this.access.patchProvision(form);
  };
}
