import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogStatusIconComponent } from '../dialog-status-icon/dialog-status-icon.component';

@Component({
  selector: 'nxt-dialog-legend',
  templateUrl: './dialog-legend.component.html',
  styleUrl: './dialog-legend.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogStatusIconComponent],
})
export class DialogLegendComponent {}
