import { inject, Injectable } from '@angular/core';
import { SubmissionRest } from './submission.rest';
import { SubmissionMapper } from '@shared/api/submission/submission.mapper';
import { SubmissionGetDTO, SubmissionPatchDTO } from '@shared/api/submission/submission.dto';

import { Submission } from '@shared/api/submission/submission.types';
import { DataLeaf } from '../base/composite-and-strategies';
import { ReadHeaders } from '@shared/api/base/api.types';

@Injectable({ providedIn: 'root' })
export class SubmissionLeaf extends DataLeaf<Submission, { getModel: SubmissionGetDTO }> {
  protected override access = inject(SubmissionRest);
  protected override mapping = inject(SubmissionMapper);

  override update(requestPayload: Partial<Submission>, readHeaders: ReadHeaders = {}) {
    return this.access.patch(this.mapping.toDTO(requestPayload) as SubmissionPatchDTO, readHeaders);
  }
}
