import { INTERNAL_USER_TYPE_LIST } from '@core/constants/global.constants';
import { RoutesConstants } from '@core/constants/routes.constants';
import { FeatureToggle, FeatureToggleSelectors } from '@core/store/feature-toggles/feature-toggles.selectors';
import { Selector } from '@ngxs/store';
import { ValidationErrors } from '@shared/api/dialog-message/dialog-message.leaf';
import { DialogMessage } from '@shared/api/dialog-message/dialog-message.types';
import { UserContext } from '@shared/api/user/user.types';
import { UniqueIDString } from '@shared/string-alias-types';
import { AuthStateSelectors } from 'src/app/auth/store/auth.selectors';
import { AuthState, AuthStateModel } from 'src/app/auth/store/auth.state';
import { DialogComposite } from '../dialog.composite';
import { DialogState, DialogStateModel } from './dialog.state';

type DialogViewModel = {
  dialogId: UniqueIDString<'Dialog'>;
  assigned: boolean;
  responseDueDate?: string;
  changedAt: string;
  assignedBusinessTopic?: {
    id: UniqueIDString<'BusinessTopic'>;
    shortDescriptionDE: string;
    shortDescriptionEN: string;
    nameDE: string;
    nameEN: string;
  } | null;
  assignedToCompany: {
    nextId: UniqueIDString<'Company'>;
    companyName: string | null;
    companyIdentifier: {
      id: UniqueIDString<'CompanyIdentifier'> | null;
      shortDescription: string | null;
      entry: string | null;
    } | null;
    deleted: boolean;
  };
  subject: string;
  relatesTo: string;
  status: 'DEADLINE_EXPIRED' | 'UNREAD_MESSAGES' | 'ACTIVE' | 'ARCHIVED' | 'NEW';
};

export type DialogOverviewViewModel = {
  dialogs: Array<DialogViewModel>;
};

export type DialogDetailsViewModel = DialogViewModel & {
  masterdataReadonly: boolean;
  archiveDialogAllowed: boolean;
  relatesToLink: string | null;
  assignmentAllowed: boolean;
  createdAt: string;
  changedBy: {
    displayName?: string;
    deleted: boolean;
  };
  companyIdentifier: {
    id: UniqueIDString<'CompanyIdentifier'>;
    shortDescription: string;
  } | null;
  assignedToUser: {
    id?: UniqueIDString<'User'>;
    fullName?: string;
    deleted: boolean;
  } | null;
  label: Array<string>;
  workArea?: {
    id: UniqueIDString<'WorkArea'>;
    displayName: string;
    color: string;
  } | null;
};

export type DialogMessageGroup = {
  date: string;
  messages: Array<
    Omit<DialogMessage, 'createdBy'> & {
      own: boolean;
      sender: string;
      senderDetails: { id: UniqueIDString<'User'>; displayUserName?: string; deleted: boolean } | null;
    }
  >;
};

export type DialogMessageViewModel = {
  editor: {
    messageIsloading: boolean;
    messageSentSuccessful: boolean;
    validationErrors?: ValidationErrors;
  };
  messageGroup: Array<DialogMessageGroup>;
};

export type DialogInfo = {
  dialogId: UniqueIDString<'Dialog'>;
  subject: string;
  relationResourceId: string;
  status: DialogViewModel['status'];
};

export class DialogSelectors {
  @Selector([DialogState, AuthState])
  static dialogs(state: DialogStateModel, authStateModel: AuthStateModel): DialogOverviewViewModel {
    const activeUserContext = AuthStateSelectors.userContext(authStateModel);
    return this.buildDialogOverviewViewModel(activeUserContext ?? null, state.domain.dialogs, state.domain.unreadDialogIds);
  }

  @Selector([DialogState])
  static isOverviewInitialLoadCompleted(state: DialogStateModel) {
    return state.ui.dialogOverview.isOverviewInitialLoadCompleted;
  }

  @Selector([DialogState, AuthState])
  static dialog(state: DialogStateModel, authStateModel: AuthStateModel): DialogDetailsViewModel | null {
    const activeUserContext = AuthStateSelectors.userContext(authStateModel);
    const featureToggles = FeatureToggleSelectors.featureToggles(authStateModel);
    if (activeUserContext && state.domain.activeDialog) {
      return this.buildDialogDetailsViewModel(activeUserContext, featureToggles, state.domain.activeDialog);
    } else {
      return null;
    }
  }

  @Selector([DialogState, AuthState])
  static dialogMessage(state: DialogStateModel, authState: AuthStateModel): DialogMessageViewModel {
    return {
      editor: {
        messageIsloading: state.ui.dialogDetails.messageIsLoading,
        messageSentSuccessful: state.ui.dialogDetails.messageSentSuccessful,
        validationErrors: state.ui.dialogDetails.validationErrors,
      },
      messageGroup: this.getMessageGroups(state, authState),
    };
  }

  @Selector([AuthState])
  static overviewColumns(authStateModel: AuthStateModel): Array<string> {
    const activeUserContext = AuthStateSelectors.userContext(authStateModel);
    if (activeUserContext?.userType === 'INTERNAL' || activeUserContext?.userType === 'INTERN') {
      return [
        'assigned',
        'businessTopic',
        'companyName',
        'subject',
        'relatesTo',
        'changedAt',
        'responseDueDate',
        'status',
        'actions',
      ];
    }
    return ['assigned', 'businessTopic', 'subject', 'relatesTo', 'changedAt', 'responseDueDate', 'status', 'actions'];
  }

  private static getMessageGroups(state: DialogStateModel, authState: AuthStateModel): Array<DialogMessageGroup> {
    const messageGroups = new Array<DialogMessageGroup>();
    if (state.domain.messages) {
      for (const key of state.domain.messages.keys()) {
        messageGroups.push({
          date: key,
          messages:
            state.domain.messages.get(key)?.map((message) => {
              return {
                ...message,
                own: message.originator === authState.userContext?.userType,
                sender: this.calculateDisplayUserNameForMessage(state, authState.userContext!, message),
                senderDetails:
                  message.createdBy ?
                    {
                      id: message.createdBy,
                      deleted:
                        // TODO: only lookup in messageUsers list as soon as external users are not provided with internal user id
                        INTERNAL_USER_TYPE_LIST.includes(authState.userContext!.userType) ?
                          !state.domain.messageUsers.find((msgUser) => msgUser.id === message.createdBy)
                        : message.originator === authState.userContext!.userType ?
                          !state.domain.messageUsers.find((msgUser) => msgUser.id === message.createdBy)
                        : false,
                    }
                  : null,
                readByExternalUser:
                  message.createdByUserType ?
                    INTERNAL_USER_TYPE_LIST.includes(message.createdByUserType) ?
                      message.readByExternalUser
                    : undefined
                  : undefined,
              };
            }) ?? [],
        });
      }
      messageGroups.sort((msg1, msg2) => -1 * msg1.date.localeCompare(msg2.date));
    }
    return messageGroups;
  }

  private static calculateDisplayUserNameForMessage(state: DialogStateModel, userContext: UserContext, message: DialogMessage) {
    if (INTERNAL_USER_TYPE_LIST.includes(userContext.userType)) {
      const user = state.domain.messageUsers.find((msgUser) => msgUser.id === message.createdBy!);
      if (message.originator === 'EXTERN') {
        return user ? `${user.firstName} ${user.lastName}, ${user.id}` : '';
      }
      return user ? `${user.firstName} ${user.lastName}` : '';
    } else {
      if (message.originator === 'EXTERN') {
        const user = state.domain.messageUsers.find((msgUser) => msgUser.id === message.createdBy!);
        return user ? `${user.firstName} ${user.lastName}, ${user.id}` : '';
      } else if (state.domain.activeDialog?.primaryContactParner != null) {
        return state.domain.activeDialog?.primaryContactParner.name;
      } else {
        return 'BBk';
      }
    }
  }

  @Selector([DialogState])
  static dialogWorkAreasAndBusinessTopics(state: DialogStateModel) {
    return state.domain.workAreas
      .map((workArea) => ({
        ...workArea,
        businessTopics: workArea.businessTopics.filter((businessTopic) => businessTopic.hasDialog),
      }))
      .filter((workArea) => workArea.businessTopics.length > 0);
  }

  @Selector([DialogState])
  static searchCompanyComplete(state: DialogStateModel) {
    return state.domain.creatingDialog?.searchCompanyComplete ?? false;
  }

  @Selector([DialogState])
  static searchedCompany(state: DialogStateModel) {
    return state.domain.creatingDialog?.company;
  }

  @Selector([DialogState])
  static companyAndBusinessTopicUserCount(state: DialogStateModel) {
    if (state.domain.creatingDialog?.searchCompanyComplete && state.domain.creatingDialog.selectedBusinessTopic) {
      return state.domain.creatingDialog.companyAndBusinessTopicUserCount ?? 0;
    }
    return undefined;
  }

  @Selector([DialogState])
  static noCompanyFound(state: DialogStateModel) {
    return state.domain.creatingDialog?.searchCompanyComplete && !state.domain.creatingDialog?.company;
  }

  @Selector([DialogState])
  static companyIdentifier(state: DialogStateModel) {
    return state.domain.creatingDialog?.companyIdentifier;
  }

  @Selector([DialogState])
  static totalLength(state: DialogStateModel) {
    return state.totalLength;
  }

  @Selector([DialogState])
  static filterState(state: DialogStateModel) {
    return state.filter;
  }

  @Selector([DialogState])
  static pagination(state: DialogStateModel) {
    return state.pagination;
  }

  @Selector([DialogState, AuthState])
  static getDialogForSubmissions(state: DialogStateModel, authState: AuthStateModel) {
    const dialogs = state.domain.external.submission.dialogs;
    if (dialogs != null) {
      const activeUserContext = AuthStateSelectors.userContext(authState);
      return dialogs.map((dialog) => ({
        dialogId: dialog.dialogId!,
        subject: dialog.subject,
        relationResourceId: dialog.relationResource.referenceCorrelationId!,
        status: this.calculateDialogStatus(activeUserContext!.id, dialog, state.domain.external.submission.unreadDialogIds),
      }));
    }
    return undefined;
  }

  @Selector([DialogState, AuthState])
  static getDialogForProvision(state: DialogStateModel, authState: AuthStateModel) {
    const dialogs = state.domain.external.provision.dialogs;
    if (dialogs != null) {
      const activeUserContext = AuthStateSelectors.userContext(authState);
      return dialogs.map((dialog) => ({
        dialogId: dialog.dialogId!,
        subject: dialog.subject,
        relationResourceId: dialog.relationResource.reference_BK_Id!,
        status: this.calculateDialogStatus(activeUserContext!.id, dialog, state.domain.external.provision.unreadDialogIds),
      }));
    }
    return undefined;
  }

  @Selector([DialogState])
  static isAutoRefreshActive(state: DialogStateModel) {
    return state.ui.dialogOverview.isAutoRefreshActive;
  }

  static buildDialogOverviewViewModel(
    activeUserContext: UserContext | null,
    dialogs: Array<DialogComposite>,
    unreadDialogIds: Array<UniqueIDString<'Dialog'>>
  ): DialogOverviewViewModel {
    if (activeUserContext === null) {
      return { dialogs: new Array<DialogViewModel>() };
    }
    const convertedDialogs = dialogs.map(
      (dialog): DialogViewModel => ({
        assigned: this.calculateDialogAssignment(activeUserContext, dialog),
        assignedBusinessTopic: dialog.assignedBusinessTopic,
        assignedToCompany: {
          nextId: dialog.company?.id ?? '',
          companyName: dialog.company?.companyName ?? null,
          companyIdentifier: dialog.company?.companyIdentifier ?? null,
          deleted: !dialog.company?.companyName,
        },
        dialogId: dialog.dialogId ?? '',
        relatesTo: dialog.relationResource.title ?? '',
        status: this.calculateDialogStatus(activeUserContext.id, dialog, unreadDialogIds),
        subject: dialog.subject,
        changedAt: dialog.changedAt ?? '',
        responseDueDate: dialog.responseDueDate,
      })
    );
    return {
      dialogs: convertedDialogs,
    };
  }

  static buildDialogDetailsViewModel(
    activeUserContext: UserContext,
    featureToggles: Map<FeatureToggle, boolean>,
    activeDialog: DialogStateModel['domain']['activeDialog']
  ): DialogDetailsViewModel {
    const dialog = activeDialog!.dialog;
    const workArea =
      dialog.workArea === null ? null
      : dialog.workArea ? { id: dialog.workArea.id, color: dialog.workArea.color, displayName: dialog.workArea.displayName }
      : undefined;
    let assignedToUser = activeDialog?.assignedUserExternal;
    if (activeUserContext.userType === 'INTERN') {
      assignedToUser = activeDialog?.assignedUserInternal;
    }
    const assigned = this.calculateDialogAssignment(activeUserContext, dialog);
    const changedByDisplayName = this.calculateChangedByDisplayName(activeUserContext, activeDialog);
    return {
      archiveDialogAllowed: (featureToggles.get('DIALOG:DETAILS|ARCHIVE') && dialog.status !== 'READ_ONLY') ?? false,
      masterdataReadonly:
        activeUserContext.userType === 'EXTERN' || activeUserContext.userType === 'EXTERNAL' || dialog.status === 'READ_ONLY',
      assigned: assigned,
      assignmentAllowed: !assigned || assignedToUser?.id !== activeUserContext.id,
      assignedBusinessTopic: dialog.assignedBusinessTopic,
      dialogId: dialog.dialogId ?? '',
      relatesTo: dialog.relationResource.title ?? '',
      relatesToLink: this.calculateRelatesToLink(dialog),
      status: this.calculateDialogStatus(activeUserContext.id, dialog, [], true),
      subject: dialog.subject,
      createdAt: dialog.createdAt ?? '',
      changedAt: dialog.changedAt ?? '',
      changedBy: {
        displayName: changedByDisplayName ?? undefined,
        deleted: !!dialog.changedBy?.id && !activeDialog?.changedByUser,
      },
      responseDueDate: dialog.responseDueDate,
      assignedToCompany: {
        nextId: dialog.assignedToCompany?.nextId ?? '',
        companyName: dialog.company?.companyName ?? null,
        companyIdentifier: dialog.company?.companyIdentifier ?? null,
        deleted: !dialog.company?.companyName,
      },
      companyIdentifier:
        dialog.companyIdentifier ?
          {
            id: dialog.companyIdentifier.id,
            shortDescription: dialog.companyIdentifier.shortDescription,
          }
        : null,
      assignedToUser:
        assignedToUser ?
          {
            id: assignedToUser.id,
            fullName: !assignedToUser.deleted ? `${assignedToUser.firstName} ${assignedToUser.lastName}` : undefined,
            deleted: assignedToUser.deleted,
          }
        : null,
      label: dialog.label,
      workArea: workArea,
    };
  }

  static calculateChangedByDisplayName(activeUserContext: UserContext, dialog: DialogStateModel['domain']['activeDialog']) {
    if (dialog?.changedByUser == undefined) {
      return null;
    } else if (
      (activeUserContext.userType === 'EXTERN' || activeUserContext.userType === 'EXTERNAL') &&
      dialog.changedByUser?.userType === 'INTERN'
    ) {
      return 'Deutsche Bundesbank';
    } else {
      return `${dialog.changedByUser.firstName} ${dialog.changedByUser.lastName} (${dialog.changedByUser.id})`;
    }
  }

  static calculateRelatesToLink(dialog: DialogComposite): string | null {
    if (dialog.relationResource.relationResourceType === 'BEREITSTELLUNG') {
      return RoutesConstants.provisionsRelative + '/details/' + dialog.relationResource.reference_BK_Id;
    } else if (['EINREICHUNG', 'EINREICHUNG_NO_FT'].includes(dialog.relationResource.relationResourceType)) {
      return RoutesConstants.submissionsStatusesDetailsRelative + '/' + dialog.relationResource.referenceCorrelationId;
    }
    return null;
  }

  static calculateDialogAssignment(activeUserContext: UserContext, dialog: DialogComposite) {
    if (INTERNAL_USER_TYPE_LIST.includes(activeUserContext.userType)) {
      return dialog.workingInternalUser !== undefined && dialog.workingInternalUser.trim().length > 0;
    } else {
      return dialog.workingExternalUser !== undefined && dialog.workingExternalUser.trim().length > 0;
    }
  }

  static calculateDialogStatus(
    currentUserId: UniqueIDString<'User'>,
    dialog: DialogComposite,
    unreadDialogIds: Array<UniqueIDString<'Dialog'>>,
    ignoreUnread = false
  ): 'DEADLINE_EXPIRED' | 'UNREAD_MESSAGES' | 'ACTIVE' | 'ARCHIVED' | 'NEW' {
    const now = new Date();
    let dialogStatus: DialogViewModel['status'] = 'ACTIVE';
    if (dialog.status === 'READ_ONLY') {
      dialogStatus = 'ARCHIVED';
    } else if (dialog.responseDueDate && Date.parse(dialog.responseDueDate) < now.getTime()) {
      dialogStatus = 'DEADLINE_EXPIRED';
    } else if (dialog.messages && dialog.messages.length > 0 && !ignoreUnread && unreadDialogIds.includes(dialog.dialogId!)) {
      dialogStatus = 'UNREAD_MESSAGES';
    }
    return dialogStatus;
  }
}
