import { Injectable, inject } from '@angular/core';
import { ReadAllOptions, ReadAllResult } from '@shared/api/base/api.types';
import { DataComposite } from '@shared/api/base/composite-and-strategies';
import { BusinessTopicLeaf } from '@shared/api/business-topic/business-topic.leaf';
import { DialogLeaf } from '@shared/api/dialog/dialog.leaf';
import { Dialog } from '@shared/api/dialog/dialog.types';
import { ProvisionLeaf } from '@shared/api/provision/provision.leaf';
import { SubmissionLeaf } from '@shared/api/submission/submission.leaf';
import { WorkAreaLeaf } from '@shared/api/work-area/work-area.leaf';
import { WorkArea } from '@shared/api/work-area/work-area.types';
import { UniqueIDString } from '@shared/string-alias-types';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { BusinessTopic } from '../work-area/business-topic/business-topic.types';
import { CompanyLeaf } from '@shared/api/company/company.leaf';
import { Company } from '@shared/api/company/company.types';
import { CompanyIdentifierLeaf } from '@shared/api/company-identifier/company-identifier.leaf';

export type DialogComposite = Omit<Dialog, 'assignedBusinessTopic'> & {
  relationResource: { title?: string };
  assignedBusinessTopic?: {
    id: UniqueIDString<'BusinessTopic'>;
    shortDescriptionDE: string;
    shortDescriptionEN: string;
    nameDE: string;
    nameEN: string;
  } | null;
  workArea?: {
    id: UniqueIDString<'WorkArea'>;
    displayName: string;
    color: string;
  } | null;
  company?: {
    id: UniqueIDString<'Company'>;
    companyName: string | null;
    companyIdentifier: {
      id: UniqueIDString<'CompanyIdentifier'> | null;
      shortDescription: string | null;
      entry: string | null;
    };
  };
};

@Injectable()
export class DialogComposition extends DataComposite<DialogComposite> {
  private dialogLeaf = inject(DialogLeaf);
  private submissionLeaf = inject(SubmissionLeaf);
  private provisionLeaf = inject(ProvisionLeaf);
  private businessTopicLeaf = inject(BusinessTopicLeaf);
  private workAreaLeaf = inject(WorkAreaLeaf);
  private companyLeaf = inject(CompanyLeaf);
  private companyIdentifierLeaf = inject(CompanyIdentifierLeaf);

  override readAllData(readAllOptions: ReadAllOptions<Dialog> = {}): Observable<ReadAllResult<DialogComposite>> {
    return this.dialogLeaf.readAllData(readAllOptions).pipe(
      switchMap((result) => {
        const businessTopicIds = result.data
          .filter((dialog) => dialog.assignedBusinessTopic?.id)
          .map((dialog) => dialog.assignedBusinessTopic!.id);
        const provisionIds = result.data
          .filter((dialog) => dialog.relationResource.relationResourceType === 'BEREITSTELLUNG')
          .map((dialog) => dialog.relationResource.reference_BK_Id!);
        const submissionIds = result.data
          .filter(
            (dialog) =>
              dialog.relationResource.relationResourceType === 'EINREICHUNG' ||
              dialog.relationResource.relationResourceType === 'EINREICHUNG_NO_FT'
          )
          .map((dialog) => dialog.relationResource.referenceCorrelationId!);
        const companyIds = result.data.map((company) => company.assignedToCompany!.nextId);
        return forkJoin({
          dialogs: of(result),
          submissions: this.readSubmissions(submissionIds),
          provisions: this.readProvisions(provisionIds),
          businessTopics: this.readBusinessTopicsById(businessTopicIds),
          workAreas: this.readWorkAreasByBusinessTopicId(businessTopicIds),
          companies: this.readCompaniesById(companyIds),
          companyIdentifiers: this.readCompanyIdentifiers(),
        });
      }),
      map(({ dialogs, submissions, provisions, businessTopics, workAreas, companies, companyIdentifiers }) => {
        const workAreaLookupMap = new Map<UniqueIDString<'BusinessTopic'>, WorkArea>();
        workAreas?.data.forEach((workArea) => {
          workArea.businessTopics.forEach((businessTopic) => {
            workAreaLookupMap.set(businessTopic.id, workArea);
          });
        });
        const businessTopicLookupMap = new Map<UniqueIDString<'BusinessTopic'>, BusinessTopic>();
        businessTopics?.data.forEach((businessTopic) => {
          businessTopicLookupMap.set(businessTopic.id, businessTopic);
        });
        const companyLookupMap = new Map<UniqueIDString<'Company'>, Company>();
        companies?.data.forEach((company) => {
          companyLookupMap.set(company.businessKey!.nextId, company);
        });
        return {
          data: dialogs.data.map((dialog) => {
            const relationResource: DialogComposite['relationResource'] = dialog.relationResource;
            if (relationResource.relationResourceType === 'BEREITSTELLUNG') {
              const provision = provisions?.data.find((p) => p.id === dialog.relationResource.reference_BK_Id);
              relationResource.title = provision?.originalFileNameWithoutPrefix ?? undefined;
            } else if (
              relationResource.relationResourceType === 'EINREICHUNG' ||
              relationResource.relationResourceType === 'EINREICHUNG_NO_FT'
            ) {
              const submission = submissions?.data.find(
                (submission) => submission.correlationId === dialog.relationResource.referenceCorrelationId
              );
              relationResource.title = submission?.fileName ?? undefined;
            }
            let businessTopic = undefined;
            if (dialog.assignedBusinessTopic?.id) {
              businessTopic = null;
            }
            if (dialog.assignedBusinessTopic?.id && businessTopicLookupMap.has(dialog.assignedBusinessTopic.id)) {
              const foundBusinessTopic = businessTopicLookupMap.get(dialog.assignedBusinessTopic.id);
              businessTopic = {
                id: foundBusinessTopic!.id,
                shortDescriptionDE: foundBusinessTopic!.shortDescriptionDE,
                shortDescriptionEN: foundBusinessTopic!.shortDescriptionEN,
                nameDE: foundBusinessTopic!.nameDE,
                nameEN: foundBusinessTopic!.nameEN,
              };
            }
            let workArea = undefined;
            let companyIdentifier = undefined;
            if (dialog.assignedBusinessTopic?.id) {
              workArea = null;
            }
            if (dialog.assignedBusinessTopic?.id && workAreaLookupMap.has(dialog.assignedBusinessTopic.id)) {
              const foundWorkArea = workAreaLookupMap.get(dialog.assignedBusinessTopic.id);
              workArea = {
                id: foundWorkArea!.id,
                color: foundWorkArea!.color,
                displayName: foundWorkArea!.abbreviation,
              };
              companyIdentifier = companyIdentifiers.find(
                (companyIdentifier) => companyIdentifier.id === foundWorkArea!.companyIdentifierId
              );
            }
            const companyIdentiferEntry = companyLookupMap
              .get(dialog.assignedToCompany!.nextId)
              ?.identifierEntries?.find((entry) => entry.businessKey.companyIdentifier_BK_key === companyIdentifier?.id);
            const company = {
              id: dialog.assignedToCompany!.nextId,
              companyName: companyLookupMap.get(dialog.assignedToCompany!.nextId)?.name ?? null,
              companyIdentifier: {
                id: companyIdentifier?.id ?? null,
                shortDescription: companyIdentifier?.abbreviation ?? null,
                entry: companyIdentiferEntry?.eintrag ?? null,
              },
            };

            return {
              ...dialog,
              relationResource: relationResource,
              workArea: workArea,
              assignedBusinessTopic: businessTopic,
              company: company,
            };
          }),
          totalLength: dialogs.totalLength,
        };
      })
    );
  }

  private readBusinessTopicsById(businessTopicIds: Array<UniqueIDString<'BusinessTopic'>>) {
    if (businessTopicIds.length === 0) {
      return of(null);
    }
    return this.businessTopicLeaf.readAllData({
      filtering: {
        concatenationMode: 'and',
        filters: [{ attribute: 'id', operator: 'in', value: businessTopicIds }],
      },
    });
  }
  private readWorkAreasByBusinessTopicId(businessTopicIds: Array<UniqueIDString<'BusinessTopic'>>) {
    if (businessTopicIds.length === 0) {
      return of(null);
    }
    return this.workAreaLeaf.readAllData({
      filtering: {
        concatenationMode: 'and',
        filters: [{ attribute: 'businessTopics[].id', operator: 'in', value: businessTopicIds }],
      },
    });
  }

  private readProvisions(provisionIds: Array<UniqueIDString<'Provision'>>) {
    if (provisionIds.length === 0) {
      return of(null);
    }
    return this.provisionLeaf.readAllData({
      filtering: {
        concatenationMode: 'and',
        filters: [{ attribute: 'id', operator: 'in', value: provisionIds }],
      },
    });
  }

  private readSubmissions(submissionIds: Array<UniqueIDString<'Submission'>>) {
    if (submissionIds.length === 0) {
      return of(null);
    }
    return this.submissionLeaf.readAllData({
      filtering: {
        concatenationMode: 'and',
        filters: [{ attribute: 'correlationId', operator: 'in', value: submissionIds }],
      },
    });
  }

  private readCompaniesById(companyIds: Array<UniqueIDString<'Company'>>) {
    if (companyIds.length === 0) {
      return of(null);
    }
    return this.companyLeaf.readAllData({
      filtering: {
        concatenationMode: 'and',
        filters: [{ attribute: 'businessKey.nextId', operator: 'in', value: companyIds }],
      },
    });
  }

  private readCompanyIdentifiers() {
    return this.companyIdentifierLeaf.readAllData().pipe(map((identifiers) => identifiers.data));
  }

  readDialogById(id: string): Observable<DialogComposite> {
    return this.dialogLeaf.read(id).pipe(
      switchMap((result) => {
        const businessTopicId = result.assignedBusinessTopic?.id;
        const provisionId =
          result.relationResource.relationResourceType === 'BEREITSTELLUNG' ? result.relationResource.reference_BK_Id! : null;
        const submissionId =
          (
            result.relationResource.relationResourceType === 'EINREICHUNG' ||
            result.relationResource.relationResourceType === 'EINREICHUNG_NO_FT'
          ) ?
            result.relationResource.referenceCorrelationId!
          : null;
        const companyId = result.assignedToCompany!.nextId;
        return forkJoin({
          dialog: of(result),
          submissions: submissionId !== null ? this.readSubmissions([submissionId]) : of(null),
          provisions: provisionId !== null ? this.readProvisions([provisionId]) : of(null),
          businessTopics: businessTopicId ? this.readBusinessTopicsById([businessTopicId]) : of(undefined),
          workAreas: businessTopicId ? this.readWorkAreasByBusinessTopicId([businessTopicId]) : of(undefined),
          companies: companyId != null ? this.readCompaniesById([companyId]) : of(null),
          companyIdentifiers: this.readCompanyIdentifiers(),
        });
      }),
      map(({ dialog, submissions, provisions, businessTopics, workAreas, companies, companyIdentifiers }) => {
        const businessTopicLookupMap = new Map<UniqueIDString<'BusinessTopic'>, BusinessTopic>();
        businessTopics?.data.forEach((businessTopic) => {
          businessTopicLookupMap.set(businessTopic.id, businessTopic);
        });
        const workAreaLookupMap = new Map<UniqueIDString<'BusinessTopic'>, WorkArea>();
        workAreas?.data.forEach((workArea) => {
          workArea.businessTopics.forEach((businessTopic) => {
            workAreaLookupMap.set(businessTopic.id, workArea);
          });
        });
        const companyLookupMap = new Map<UniqueIDString<'Company'>, Company>();
        companies?.data.forEach((company) => {
          companyLookupMap.set(company.businessKey!.nextId, company);
        });
        const relationResource: DialogComposite['relationResource'] = dialog.relationResource;
        if (relationResource.relationResourceType === 'BEREITSTELLUNG') {
          const provision = provisions?.data.find((p) => p.id === dialog.relationResource.reference_BK_Id);
          relationResource.title = provision?.originalFileNameWithoutPrefix ?? undefined;
        } else if (
          relationResource.relationResourceType === 'EINREICHUNG' ||
          relationResource.relationResourceType === 'EINREICHUNG_NO_FT'
        ) {
          const submission = submissions?.data.find(
            (submission) => submission.correlationId === dialog.relationResource.referenceCorrelationId
          );
          relationResource.title = submission?.fileName ?? undefined;
        }
        let businessTopic = undefined;
        if (dialog.assignedBusinessTopic?.id) {
          businessTopic = null;
        }
        if (dialog.assignedBusinessTopic?.id && businessTopicLookupMap.has(dialog.assignedBusinessTopic.id)) {
          const foundBusinessTopic = businessTopicLookupMap.get(dialog.assignedBusinessTopic.id);
          businessTopic = {
            id: foundBusinessTopic!.id,
            shortDescriptionDE: foundBusinessTopic!.shortDescriptionDE,
            shortDescriptionEN: foundBusinessTopic!.shortDescriptionEN,
            nameDE: foundBusinessTopic!.nameDE,
            nameEN: foundBusinessTopic!.nameEN,
          };
        }
        let workArea = undefined;
        let companyIdentifier = undefined;
        if (dialog.assignedBusinessTopic?.id) {
          workArea = null;
        }
        if (dialog.assignedBusinessTopic?.id && workAreaLookupMap.has(dialog.assignedBusinessTopic.id)) {
          const foundWorkArea = workAreaLookupMap.get(dialog.assignedBusinessTopic.id);
          workArea = {
            id: foundWorkArea!.id,
            color: foundWorkArea!.color,
            displayName: foundWorkArea!.abbreviation,
          };
          companyIdentifier = companyIdentifiers.find(
            (companyIdentifier) => companyIdentifier.id === foundWorkArea!.companyIdentifierId
          );
        }

        const companyIdentiferEntry = companyLookupMap
          .get(dialog.assignedToCompany!.nextId)
          ?.identifierEntries?.find((entry) => entry.businessKey.companyIdentifier_BK_key === companyIdentifier?.id);
        const company = {
          id: dialog.assignedToCompany!.nextId,
          companyName: companyLookupMap.get(dialog.assignedToCompany!.nextId)?.name ?? null,
          companyIdentifier: {
            id: companyIdentifier?.id ?? null,
            shortDescription: companyIdentifier?.abbreviation ?? null,
            entry: companyIdentiferEntry?.eintrag ?? null,
          },
        };
        return {
          ...dialog,
          relationResource: relationResource,
          workArea: workArea,
          assignedBusinessTopic: businessTopic,
          company: company,
        };
      })
    );
  }
}
