<nxt-dialog>
    <nxt-modal-header>
        <div class="nxt-blue-marker nxt-ellipsis">
          {{ "DIALOG.CREATE_MODAL.TITLE" | translate }}
        </div>

    </nxt-modal-header>

    <nxt-modal-body>
        <p class="description nxt-accent-800">
            {{ "DIALOG.CREATE_MODAL.SUBMISSION.DESCRIPTION"  | translate }}
        </p>

        <p class="description nxt-accent-800">
          <b>{{ data.fileName }}</b>
        </p>

        <div [formGroup]="formGroup" class="card">

            <div class="d-flex flex-column">
              <mat-form-field class="mb-4">
                <mat-label>{{ "DIALOG.CREATE_MODAL.SUBMISSION.LABEL.WORK_AREA_NEXT_OPERATIONS"  | translate }}</mat-label>
                <mat-select  data-test-id="select-workArea" disableOptionCentering formControlName="workArea" [required]="data.withBusinessTopic" [value]="workAreas()?.at(0)">
                    <mat-option *ngFor="let workArea of workAreas();index as i;" [value]="workArea">
                        {{ currentLanguage() === 'en' ? workArea.nameEN : workArea.nameDE }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls.workArea.getError('required')">
                  {{ 'PROBLEMCODES.20015' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="mb-4">
                <mat-label>{{ "DIALOG.CREATE_MODAL.SUBMISSION.LABEL.BUSINESS_TOPIC_SUBJECT"  | translate }}</mat-label>
                <mat-select data-test-id="select-businessTopic"  disableOptionCentering formControlName="businessTopic" [required]="data.withBusinessTopic" [value]="selectedWorkArea()?.businessTopics?.at(0)">
                    <mat-option *ngFor="let businessTopic of selectedWorkArea()?.businessTopics" [value]="businessTopic">
                            {{ currentLanguage() === 'en' ? businessTopic.nameEN : businessTopic.nameDE }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls.businessTopic.getError('required')">
                  {{ 'PROBLEMCODES.20015' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ "DIALOG.CREATE_MODAL.SUBMISSION.LABEL.SUBJECT" | translate }}</mat-label>
                <input matInput #inpSubject data-test-id="input-subject" formControlName="subject" maxLength="40" />
                <mat-hint>{{inpSubject.value.length}} / {{inpSubject.maxLength}}</mat-hint>
                <mat-error *ngIf="formGroup.controls.subject.getError('required')">
                  {{inpSubject.value.length}} / {{inpSubject.maxLength}} - {{ 'PROBLEMCODES.20015' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
    </nxt-modal-body>

    <nxt-modal-footer>
      <mat-dialog-actions>
        <button class="me-3"
                data-test-id="create-dialog-modal-cancel-btn"
                mat-stroked-button
                color="primary"
                [mat-dialog-close]="null"
                [attr.aria-label]="'COMMON.CANCEL' | translate">
          {{'COMMON.CANCEL' | translate}}
        </button>
        <button class=""
                data-test-id="create-dialog-modal-save-btn"
                mat-raised-button
                color="primary"
                (click)="onSave()"
                [disabled]="!formGroup.valid"
                [attr.aria-label]="'DIALOG.CREATE_MODAL.BUTTON.CONFIRM' | translate">
          {{'DIALOG.CREATE_MODAL.BUTTON.CONFIRM' | translate}}
        </button>
      </mat-dialog-actions>
    </nxt-modal-footer>
</nxt-dialog>
