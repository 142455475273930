import { Injectable } from '@angular/core';

import { FullPropertyPathOf, PropertyPathForNExtQueryEngine, RecursiveConcrete } from '@shared/types';
import { RestBaseMapper } from '../base/rest-base.mapper';
import { DialogMessageGetDTO, DialogMessagePostDTO } from './dialog-message.dto';
import { DialogMessage } from './dialog-message.types';

@Injectable({
  providedIn: 'root',
})
export class DialogMessageMapper extends RestBaseMapper<DialogMessage, DialogMessageGetDTO> {
  override toClientModel(dto: DialogMessageGetDTO): DialogMessage {
    return {
      id: dto.businessKey.msgId,
      dialogId: dto.dialogBusinessKey.dialogId,
      status: dto.status,
      text: dto.msgTxt,
      attachments: dto.bereitstellungen,
      createdAt: dto.createdAt,
      createdBy: dto.createdByNutzerBusinessKey?.userId,
      readByExternalUser: dto.readByExternalUser,
      businessTopicId: dto.fachthemaBusinessKey?.fKey,
      companyId: dto.unternehmenBusinessKey.nextId,
      originator: dto.originator,
      createdByUserType: dto.createdByUserType,
    };
  }

  toPostDTO(message: DialogMessage): DialogMessagePostDTO {
    return {
      dialogBusinessKey: { dialogId: message.dialogId },
      msgTxt: message.text,
      status: message.status,
    };
  }

  override mapPropertyPathsFromClientToDTO(
    property: FullPropertyPathOf<DialogMessage>
  ): PropertyPathForNExtQueryEngine<RecursiveConcrete<DialogMessageGetDTO>> {
    switch (property) {
      case 'dialogId':
        return 'dialogBusinessKey.dialogId';
      case 'createdAt':
        return 'createdAt';
      default:
        throw new Error(`Property ${property} not found in mapping.`);
    }
  }
}
