<div class="section-legend">
    <div class="legend-dialog-container">
        <nxt-dialog-status-icon statusValue="DEADLINE_EXPIRED" [withLabel]="true"/>
    </div>

    <div class="legend-dialog-container">
        <nxt-dialog-status-icon statusValue="UNREAD_MESSAGES" [withLabel]="true"/>
    </div>

    <div class="legend-dialog-container">
        <nxt-dialog-status-icon statusValue="ACTIVE" [withLabel]="true"/>
    </div>

    <div class="legend-dialog-container">
        <nxt-dialog-status-icon statusValue="NEW" [withLabel]="true"/>
    </div>

    <div class="legend-dialog-container">
        <nxt-dialog-status-icon statusValue="ARCHIVED" [withLabel]="true"/>
    </div>
</div>
