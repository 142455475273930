import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { LanguageService } from '@core/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { WorkArea } from '@shared/api/work-area/work-area.types';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { FilterTextComponent } from '@shared/components/filter-text/filter-text.component';
import { InfoPanelComponent } from '@shared/components/info-panel/info-panel.component';
import { BusinessTopic } from 'src/app/work-area/business-topic/business-topic.types';
import { DialogSelectors } from '../../store/dialog.selectors';
import { CreateDialogData } from '../create-dialog.model';

export type CreateDialogWithContextInitializationData = {
  referencedEntityId?: string;
  fileName?: string;
  withBusinessTopic: boolean;
};

@Component({
  selector: 'nxt-create-dialog-with-context',
  templateUrl: './create-dialog-with-context.component.html',
  styleUrls: ['./create-dialog-with-context.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogComponent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    MatError,
    InfoPanelComponent,
    FilterTextComponent,
    MatCheckbox,
    MatInput,
    MatHint,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateDialogWithContextComponent {
  private dialogRef = inject(MatDialogRef<CreateDialogWithContextComponent>);
  private store = inject(Store);
  currentLanguage = inject(LanguageService).currentLanguage;

  formGroup = new FormGroup({
    workArea: new FormControl<WorkArea | null>({ value: null, disabled: true }, Validators.required),
    businessTopic: new FormControl<BusinessTopic | null>({ value: null, disabled: true }, Validators.required),
    subject: new FormControl<string | null>(null, Validators.required),
  });

  workAreas = toSignal(this.store.select(DialogSelectors.dialogWorkAreasAndBusinessTopics));
  selectedWorkArea = toSignal(this.formGroup.controls.workArea.valueChanges);
  selectedBusinessTopic = toSignal(this.formGroup.controls.businessTopic.valueChanges);

  constructor(@Inject(MAT_DIALOG_DATA) public data: CreateDialogWithContextInitializationData) {}

  onSave() {
    this.dialogRef.close(this.createDialogData());
  }

  private createDialogData(): CreateDialogData | void {
    const selectedWorkArea = this.formGroup.controls.workArea.value;
    const selectedBusinessTopic = this.formGroup.controls.businessTopic.value;
    const subject = this.formGroup.controls.subject.value;

    if (
      (this.data.withBusinessTopic && (!selectedWorkArea || !selectedBusinessTopic || !subject)) ||
      (!this.data.withBusinessTopic && !subject)
    ) {
      return;
    }

    return {
      workArea: selectedWorkArea,
      businessTopic: selectedBusinessTopic,
      subject: subject!,
    };
  }
}
