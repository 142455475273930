import { Injectable } from '@angular/core';
import { FullAndInnerArrayPropertyPathOf, PropertyPathForNExtQueryEngine, RecursiveConcrete } from '@shared/types';
import { RestBaseMapper } from '../base/rest-base.mapper';
import { DialogGetDTO, DialogPatchDTO, DialogPostDTO } from './dialog.dto';
import { Dialog } from './dialog.types';

@Injectable({
  providedIn: 'root',
})
export class DialogMapper extends RestBaseMapper<Dialog, DialogGetDTO> {
  override toClientModel(dto: DialogGetDTO): Dialog {
    const assignedBusinessTopic =
      dto.fachthemaBusinessKey ?
        {
          id: dto.fachthemaBusinessKey.fKey,
          shortDescription: dto.fachthemaKurzBezeichnung,
        }
      : null;
    const companyIdentifier =
      dto.fachidentifikatorBusinessKey ?
        {
          id: dto.fachidentifikatorBusinessKey.key,
          shortDescription: dto.fachidentifikatorKurz ?? '',
        }
      : null;
    return {
      assignedToCompany: {
        nextId: dto.unternehmenBusinessKey.nextId,
        companyIdentifierEntry: dto.fidEintrag,
      },
      createdAt: dto.createdAt,
      dialogId: dto.businessKey.dialogId,
      label: dto.label ? new Array<string>(...dto.label) : new Array<string>(),
      messages: dto.messages ? new Array<string>(...dto.messages) : new Array<string>(),
      relationResource: {
        reference_BK_Id: dto.relationResource.reference_BK_Id,
        referenceCorrelationId: dto.relationResource.referenceCorrelationId,
        relationResourceType: dto.relationResource.relationResourceType,
      },
      status: dto.status,
      processingStatus: dto.processingStatus,
      subject: dto.betreff ?? '',
      assignedBusinessTopic: assignedBusinessTopic,
      changedAt: dto.changedAt,
      changedBy:
        dto.changedByNutzerBusinessKey ?
          { id: dto.changedByNutzerBusinessKey.userId, userType: dto.changedByUserType! }
        : undefined,
      companyIdentifier: companyIdentifier,
      createdBy: dto.createdByNutzerBusinessKey?.userId,
      responseDueDate: dto.responseDueDate,
      workingExternalUser: dto.workingExternalNutzerBusinessKey?.userId,
      workingInternalUser: dto.workingInternalNutzerBusinessKey?.userId,
    };
  }

  override toDTO(data: Partial<Dialog>): DialogPatchDTO {
    const dialogPatchDto: DialogPatchDTO = {
      businessKey: { dialogId: data.dialogId! },
    };
    if (data.workingExternalUser != null) {
      dialogPatchDto.workingExternalNutzerBusinessKey = { userId: data.workingExternalUser };
    }
    if (data.workingInternalUser != null) {
      dialogPatchDto.workingInternalNutzerBusinessKey = { userId: data.workingInternalUser };
    }
    return dialogPatchDto;
  }

  toPostDTO(data: Partial<Dialog>): DialogPostDTO {
    const dialogPostDto: DialogPostDTO = {
      relationResource: {
        relationResourceType: data.relationResource!.relationResourceType,
        reference_BK_Id: data.relationResource!.reference_BK_Id,
        referenceCorrelationId: data.relationResource!.referenceCorrelationId,
      },
      betreff: data.subject,
      fidEintrag: data.assignedToCompany?.companyIdentifierEntry ?? undefined,
      fachthemaBusinessKey: data.assignedBusinessTopic ? { fKey: data.assignedBusinessTopic.id } : undefined,
    };
    return dialogPostDto;
  }

  override mapPropertyPathsFromClientToDTO(
    property: FullAndInnerArrayPropertyPathOf<Dialog>
  ): PropertyPathForNExtQueryEngine<RecursiveConcrete<DialogGetDTO>> {
    switch (property) {
      case 'createdAt':
        return 'createdAt';
      case 'status':
        return 'status';
      case 'processingStatus':
        return 'processingStatus';
      case 'messages':
        return 'messages';
      case 'assignedBusinessTopic':
        return 'fachthemaBusinessKey.fKey';
      case 'relationResource.referenceCorrelationId':
        return 'relationResource.referenceCorrelationId';
      case 'relationResource.relationResourceType':
        return 'relationResource.relationResourceType';
      case 'relationResource.reference_BK_Id':
        return 'relationResource.reference_BK_Id';
      case 'workingExternalUser':
        return 'workingExternalNutzerBusinessKey.userId';
      case 'workingInternalUser':
        return 'workingInternalNutzerBusinessKey.userId';
      default:
        throw new Error(`Property ${property} not found in mapping.`);
    }
  }
}
