<nxt-dialog>
    <nxt-modal-header>
        <div class="nxt-blue-marker nxt-ellipsis">
          {{ "DIALOG.CREATE_MODAL.TITLE" | translate }}
        </div>

    </nxt-modal-header>

    <nxt-modal-body>
        <p class="description nxt-accent-800">
            {{ "DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.DESCRIPTION"  | translate }}
        </p>

        <div [formGroup]="formGroup" class="card">
          <div class="mb-5">
            <h3>{{ "DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.SUBTITLE.BUSINESS_TOPIC" | translate }}</h3>
            <div class="d-flex flex-column mt-2">
              <mat-form-field>
                <mat-label>{{ "DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.LABEL.WORK_AREA_NEXT_OPERATIONS"  | translate }}</mat-label>
                <mat-select  data-test-id="select-workArea" disableOptionCentering formControlName="workArea" [compareWith]="compareSelection" required>
                    <mat-option *ngFor="let workArea of workAreas()" [value]="workArea">
                        {{ currentLanguage() === 'en' ? workArea.nameEN : workArea.nameDE }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls.workArea.getError('required')">
                  {{ 'PROBLEMCODES.20015' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.LABEL.BUSINESS_TOPIC_SUBJECT"  | translate }}</mat-label>
                <mat-select data-test-id="select-businessTopic"  disableOptionCentering formControlName="businessTopic" [compareWith]="compareSelection" required>
                    <mat-option *ngFor="let businessTopic of selectedWorkArea()?.businessTopics" [value]="businessTopic">
                            {{ currentLanguage() === 'en' ? businessTopic.nameEN : businessTopic.nameDE }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.controls.businessTopic.getError('required')">
                  {{ 'PROBLEMCODES.20015' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="mb-5">
            <h3>{{ "DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.SUBTITLE.COMPANY_IDENTIFIER" | translate }}</h3>
            <div class="my-4">
                <nxt-info-panel
                  [translationKey]="displayUserCount() ? 'DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.INFO.PERMITTED_USERS' : 'DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.INFO.SELECT_WORK_AREA_FIRST'"
                  [translationProps]="{userCount: userCount()}" />
            </div>
            <div class="row d-flex justify-content-between mb-3">
              <div class="col-5">
                <nxt-filter-text
                  [label]="(!isCompanyIdentifierEntryDisabled() && companyIdentifier()?.name) ? companyIdentifier()?.name : ('DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.LABEL.COMPANY_IDENTIFIER' | translate)"
                  [maxTextLength]="64"
                  (instantValueChanges)="onCompanyIdentifierEntryChangedInstant($event)"
                  (debouncedValueChanges)="onCompanyIdentifierEntryChanged()"
                  [minTextLength]="0"
                  [hint]="foundCompany()?.name ?? ''"
                  [disabled]="isCompanyIdentifierEntryDisabled()"
                  [required]="true"
                  [value]="enteredCompanyIdentifierEntry() ?? ''"
                  [error]="companyIdentifierEntryError()"
                  (touched)="onCompanyIdentifierTouch()"
                  (blurred)="onCompanyIdentifierBlur()" />
              </div>
            </div>
            <div>
              <mat-checkbox  data-test-id="cb-all-companies" class="w-100" formControlName="isAllCompanies">
                {{'DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.LABEL.IS_ALL_COMPANIES' | translate}}
              </mat-checkbox>
            </div>
          </div>

          <div class="mb-4">
            <h3>{{ "DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.SUBTITLE.SUBJECT" | translate }}</h3>
            <div class="mt-4">
              <mat-form-field>
                <mat-label>{{ "DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.LABEL.SUBJECT" | translate }}</mat-label>
                <input #inpSubject data-test-id="input-subject" matInput formControlName="subject" maxLength="40" />
                <mat-hint>{{inpSubject.value.length}} / {{inpSubject.maxLength}}</mat-hint>
                <mat-error *ngIf="formGroup.controls.subject.getError('required')">
                  {{inpSubject.value.length}} / {{inpSubject.maxLength}} - {{ 'PROBLEMCODES.20015' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
    </nxt-modal-body>

    <nxt-modal-footer>
      <mat-dialog-actions>
        <button class="me-3"
                data-test-id="create-dialog-modal-cancel-btn"
                mat-stroked-button
                color="primary"
                [mat-dialog-close]="null"
                [attr.aria-label]="'COMMON.CANCEL' | translate">
          {{'COMMON.CANCEL' | translate}}
        </button>
        <button class=""
                data-test-id="create-dialog-modal-save-btn"
                mat-raised-button
                color="primary"
                (click)="onSave()"
                [disabled]="!formGroup.valid || !loadingComplete() || noCompanyFound()"
                [attr.aria-label]="'DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.BUTTON.CONFIRM' | translate">
          {{'DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.BUTTON.CONFIRM' | translate}}
        </button>
      </mat-dialog-actions>
    </nxt-modal-footer>
</nxt-dialog>
