import { Injectable } from '@angular/core';
import { ApiConstants } from '@core/constants/api.constants';
import { SubmissionGetDTO, SubmissionPatchDTO, SubmissionPostDTO, SubmissionPutDTO } from '@shared/api/submission/submission.dto';
import { environment } from '../../../../build-environments/environment';
import { RestAPIAdapter } from '../base/rest-api.adapter';

@Injectable({ providedIn: 'root' })
export class SubmissionRest extends RestAPIAdapter<
  SubmissionGetDTO,
  { getModel: SubmissionGetDTO; patchModel: SubmissionPatchDTO; postModel: SubmissionPostDTO; putModel: SubmissionPutDTO }
> {
  microServiceCoordinates = {
    baseUri: `${environment.apiBaseUrl}/einreichung/api`,
    version: 'v1.0.0',
  };
  resourceUrl = `${this.microServiceCoordinates.baseUri}/${this.microServiceCoordinates.version}/${ApiConstants.SUBMISSION_API_PATH}`;
}
