<ng-container [ngSwitch]="statusValue">

    <span data-test-id="DEADLINE_EXPIRED" *ngSwitchCase="'DEADLINE_EXPIRED'" [attr.aria-label]="'COMMON.AXA.ICON.DIALOG_STATUS.DEADLINE_EXPIRED' | translate" class="d-flex align-items-center justify-content-start">
        <mat-icon class="material-icons-outlined nxt-error-color" aria-hidden="true" [ngStyle]="getIconStyling()" [ngClass]="cssClass">feedback</mat-icon>
        <span *ngIf="withLabel" class="ms-2">{{'COMMON.AXA.ICON.DIALOG_STATUS.DEADLINE_EXPIRED' | translate}}</span>
    </span>

    <span data-test-id="UNREAD_MESSAGES" *ngSwitchCase="'UNREAD_MESSAGES'" [attr.aria-label]="'COMMON.AXA.ICON.DIALOG_STATUS.UNREAD_MESSAGES' | translate" class="d-flex align-items-center justify-content-start">
        <mat-icon class="material-icons-outlined nxt-primary-color" aria-hidden="true" [ngStyle]="getIconStyling()" [ngClass]="cssClass">mark_chat_unread</mat-icon>
        <span *ngIf="withLabel" class="ms-2">{{'COMMON.AXA.ICON.DIALOG_STATUS.UNREAD_MESSAGES' | translate}}</span>
    </span>

    <span data-test-id="ACTIVE" *ngSwitchCase="'ACTIVE'" [attr.aria-label]="'COMMON.AXA.ICON.DIALOG_STATUS.ACTIVE' | translate" class="d-flex align-items-center justify-content-start">
        <mat-icon class="material-icons-outlined nxt-primary-color" aria-hidden="true" [ngStyle]="getIconStyling()" [ngClass]="cssClass">chat_bubble_outline</mat-icon>
        <span *ngIf="withLabel" class="ms-2">{{'COMMON.AXA.ICON.DIALOG_STATUS.ACTIVE' | translate}}</span>
    </span>

    <span data-test-id="NEW" *ngSwitchCase="'NEW'" [attr.aria-label]="'COMMON.AXA.ICON.DIALOG_STATUS.NEW' | translate" class="d-flex align-items-center justify-content-start">
        <mat-icon class="nxt-primary-color flipped" aria-hidden="true" [ngStyle]="getIconStyling()" [ngClass]="cssClass">add_comment</mat-icon>
        <span *ngIf="withLabel" class="ms-2">{{'COMMON.AXA.ICON.DIALOG_STATUS.NEW' | translate}}</span>
    </span>

    <span data-test-id="ARCHIVED" *ngSwitchCase="'ARCHIVED'" [attr.aria-label]="'COMMON.AXA.ICON.DIALOG_STATUS.ARCHIVED' | translate" class="d-flex align-items-center justify-content-start">
        <mat-icon class="material-icons-outlined nxt-accent-700" aria-hidden="true" [ngStyle]="getIconStyling()" [ngClass]="cssClass">mark_chat_read</mat-icon>
        <span *ngIf="withLabel" class="ms-2">{{'COMMON.AXA.ICON.DIALOG_STATUS.ARCHIVED' | translate}}</span>
    </span>
</ng-container>
