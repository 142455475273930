import { Injectable } from '@angular/core';
import { RestAPIAdapter } from '../base/rest-api.adapter';
import { environment } from 'src/build-environments/environment';
import { ApiConstants } from '@core/constants/api.constants';
import { DialogMessageGetDTO } from './dialog-message.dto';
import { ReadHeaders } from '../base/api.types';
import { Observable, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogMessageRest extends RestAPIAdapter<DialogMessageGetDTO> {
  microServiceCoordinates = {
    baseUri: `${environment.apiBaseUrl}/${ApiConstants.DIALOG_API_PATH}/api`,
    version: 'v1.0.0',
  };
  resourceUrl = `${this.microServiceCoordinates.baseUri}/${this.microServiceCoordinates.version}/${ApiConstants.DIALOG_MESSAGE_API_PATH}`;

  createDialogMessage(createDTO: any, readHeaders?: ReadHeaders): Observable<string> {
    return super.create(createDTO, readHeaders).pipe(
      map((response) => {
        if (response.status !== 200 && response.status !== 201) {
          throwError(() => new Error('DialogMessage creation failed'));
        }
        const id = super.extractIdFromURL(response.headers.get('Location'));
        if (id === undefined || id.trim().length === 0) {
          throwError(() => new Error('No DialogMessageId available'));
        }
        return id!;
      })
    );
  }
}
