import { Injectable, inject } from '@angular/core';
import { getCleanObject } from '@shared/functions';
import { Observable, catchError, of } from 'rxjs';
import { ReadAllOptions, ReadAllResult, ReadHeaders } from '../base/api.types';
import { DataLeaf } from '../base/composite-and-strategies';
import { ErrorResponse as ApiErrorResponse } from '../base/error-response.model';
import { DialogMessageGetDTO } from './dialog-message.dto';
import { DialogMessageMapper } from './dialog-message.mapper';
import { DialogMessageRest } from './dialog-message.rest';
import { DialogMessage } from './dialog-message.types';

export type ValidationMessage = {
  translationKey: string;
  messageParams: {
    [key: number]: string;
  };
};

export type ValidationErrors = Map<string, ValidationMessage[]>;
// TODO/IMPROVE map key typing
// type ValidationErrors = Map<FullPropertyPathOf<DialogMessage>, ValidationMessage[]>;

export type ErrorResponse = {
  type: 'PERSISTENCE_VALIDATION' | 'INPUT_VALIDATION' | 'OTHER';
  errors: ValidationErrors | ValidationMessage;
};

@Injectable({ providedIn: 'root' })
export class DialogMessageLeaf extends DataLeaf<DialogMessage, { getModel: DialogMessageGetDTO }> {
  protected override access = inject(DialogMessageRest);
  protected override mapping = inject(DialogMessageMapper);

  override readAllData(
    readAllOptions: ReadAllOptions<DialogMessage> = {},
    headers: ReadHeaders = {}
  ): Observable<ReadAllResult<DialogMessage>> {
    return super.readAllData(readAllOptions, headers).pipe(catchError(() => of({ data: [], totalLength: 0 })));
  }

  override create(message: DialogMessage) {
    return this.access.createDialogMessage(getCleanObject(this.mapping.toPostDTO(message))).pipe(
      catchError((err: ApiErrorResponse<DialogMessage>) => {
        let problemValidationMessage: ValidationMessage | undefined = undefined;

        if (err.error?.problem) {
          problemValidationMessage = {
            translationKey: `PROBLEMCODES.${err.error.problem.code}`,
            messageParams: err.error.problem.codeAnnotations,
          };
        }

        const validationErrors: ValidationErrors = new Map();

        if (err.error?.problems) {
          Object.entries(err.error.problems).forEach(([propertyName, propertyProblems]) => {
            validationErrors.set(
              propertyName, // TODO/IMPROVE: as FullPropertyPathOf<DialogMessage>,
              propertyProblems.map((problem) => {
                return {
                  translationKey: `PROBLEMCODES.${problem.code}`,
                  messageParams: problem.codeAnnotations,
                };
              })
            );
          });
        }

        const validationError: ErrorResponse = {
          type:
            err?.error?.type === 'INPUT VALIDATION FAILURE' && +err.error.status === 400 ? 'INPUT_VALIDATION'
            : err?.error?.status === 409 ? 'PERSISTENCE_VALIDATION'
            : 'OTHER',
          errors: problemValidationMessage ?? validationErrors,
        };

        throw validationError;
      })
    );
  }
}
