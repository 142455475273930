import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { INTERNAL_USER_TYPE_LIST } from '@core/constants/global.constants';
import { RoutesConstants } from '@core/constants/routes.constants';
import { SnackbarService } from '@core/services/snackbar.service';
import { FeatureToggleSelectors } from '@core/store/feature-toggles/feature-toggles.selectors';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, NgxsOnInit, Action as OnAction, State, StateContext, StateToken, Store, ofAction } from '@ngxs/store';
import { CompanyIdentifier } from '@shared/api/company-identifier/company-identifier.types';
import { Company } from '@shared/api/company/company.types';
import { ErrorResponse, ValidationErrors } from '@shared/api/dialog-message/dialog-message.leaf';
import { DialogMessage } from '@shared/api/dialog-message/dialog-message.types';
import { Dialog } from '@shared/api/dialog/dialog.types';
import { SseService } from '@shared/api/server-sent-events/sse.service';
import { User } from '@shared/api/user/user.types';
import { WorkArea } from '@shared/api/work-area/work-area.types';
import { FilterStateModel } from '@shared/store/filter/filter.types';
import { UniqueIDString } from '@shared/string-alias-types';
import { FilterSetting } from '@shared/types';
import produce from 'immer';
import {
  Observable,
  bufferTime,
  catchError,
  combineLatestWith,
  filter,
  forkJoin,
  interval,
  map,
  merge,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AuthStateSelectors } from 'src/app/auth/store/auth.selectors';
import { BusinessTopic } from 'src/app/work-area/business-topic/business-topic.types';
import { CreateDialogData } from '../create-dialog/create-dialog.model';
import { CreateDialogExternalUserComponent } from '../create-dialog/external-user/create-dialog-external-user.component';
import { CreateDialogNextOperationsComponent } from '../create-dialog/next-operations/create-dialog-next-operations.component';
import {
  CreateDialogWithContextComponent,
  CreateDialogWithContextInitializationData,
} from '../create-dialog/with-context/create-dialog-with-context.component';
import { DialogComposite } from '../dialog.composite';
import { DIALOG_POLLING_INTERVAL_MS } from '../dialog.constants';
import { DialogDAO } from '../dialog.dao';
import { Details, DialogModals, EXTERNAL, Overview } from './dialog.actions';
import { DialogSelectors } from './dialog.selectors';

export const DIALOG_STATE_NAME = 'dialogState';

export const DIALOG_STATE = new StateToken<DialogStateModel>(DIALOG_STATE_NAME);

export type DialogStateModel = FilterStateModel & {
  pagination: {
    pageIndex: number;
    pageSize: number;
    pageSizeOptions: Array<number>;
  };
  totalLength?: number;
  ui: {
    dialogOverview: {
      isOverviewInitialLoadCompleted: boolean;
      isAutoRefreshActive: boolean;
    };
    dialogDetails: {
      messageSentSuccessful: boolean;
      messageIsLoading: boolean;
      validationErrors?: ValidationErrors;
    };
  };
  domain: {
    dialogs: Array<DialogComposite>;
    unreadDialogIds: Array<UniqueIDString<'Dialog'>>;
    creatingDialog?: {
      selectedBusinessTopic?: BusinessTopic;
      searchCompanyComplete?: boolean;
      company?: Company;
      companyIdentifier?: CompanyIdentifier;
      companyAndBusinessTopicUserCount?: number;
    };
    activeDialog: {
      dialog: DialogComposite;
      primaryContactParner: BusinessTopic['contactPartners'][number] | null;
      companyIdentifier?: {
        companyIdentifierId: UniqueIDString<'CompanyIdentifier'>;
        name: string;
      };
      changedByUser?: { id: UniqueIDString<'User'>; firstName: string; lastName: string; userType: 'INTERN' | 'EXTERN' };
      assignedUserExternal: {
        id: UniqueIDString<'User'>;
        firstName?: string;
        lastName?: string;
        deleted: boolean;
      } | null;
      assignedUserInternal: {
        id: UniqueIDString<'User'>;
        firstName?: string;
        lastName?: string;
        deleted: boolean;
      } | null;
    } | null;
    messages: Map<string, Array<DialogMessage>> | null;
    messageUsers: Array<User>;
    workAreas: WorkArea[];
    external: {
      submission: {
        dialogs: Array<DialogComposite>;
        unreadDialogIds: Array<UniqueIDString<'Dialog'>>;
      };
      provision: {
        dialogs: Array<DialogComposite>;
        unreadDialogIds: Array<UniqueIDString<'Dialog'>>;
      };
    };
  };
};

@Injectable()
@State<DialogStateModel>({
  name: DIALOG_STATE_NAME,
  defaults: {
    pagination: {
      pageIndex: 0,
      pageSize: 10,
      pageSizeOptions: [10, 25, 50, 100],
    },
    filter: {
      optionGroups: [],
    },
    ui: {
      dialogOverview: {
        isOverviewInitialLoadCompleted: false,
        isAutoRefreshActive: true,
      },
      dialogDetails: {
        messageSentSuccessful: true,
        messageIsLoading: false,
      },
    },
    domain: {
      dialogs: new Array<DialogComposite>(),
      unreadDialogIds: [],
      activeDialog: null,
      messages: null,
      messageUsers: new Array<User>(),
      workAreas: [],
      external: {
        submission: {
          dialogs: new Array<DialogComposite>(),
          unreadDialogIds: [],
        },
        provision: {
          dialogs: new Array<DialogComposite>(),
          unreadDialogIds: [],
        },
      },
    },
  },
})
export class DialogState implements NgxsOnInit {
  constructor(
    private dialogDAO: DialogDAO,
    private store: Store,
    private matDialog: MatDialog,
    private zone: NgZone,
    private snackbarService: SnackbarService,
    private sseService: SseService,
    private actions$: Actions
  ) {}

  ngxsOnInit(ctx: StateContext<DialogStateModel>) {
    this.startSSEListener(ctx);
  }

  @OnAction(Overview.Enter)
  overviewEnter(ctx: StateContext<DialogStateModel>, {}) {
    ctx.dispatch(new Overview.GetDialogs());
    this.listenForNotificationEventsImportantForOverview(ctx);
  }

  @OnAction(Overview.GetDialogs)
  getDialogs(ctx: StateContext<DialogStateModel>, {}) {
    const isCurrentUserInternal = this.store.selectSnapshot(AuthStateSelectors.isInternalUser);
    const isCurrentUserOperations = this.store.selectSnapshot(AuthStateSelectors.isOperationsUser);
    const paginationState = ctx.getState().pagination;

    const filtersSetInUI = new Array<FilterSetting<Dialog>>();
    // Default filter: filter out dialogs which have no messages
    const processingStatus: Array<Dialog['processingStatus']> = ['READY_WITH_MSG'];
    if (isCurrentUserInternal) {
      processingStatus.push('INTERN_NO_MSG');
    } else {
      processingStatus.push('EXTERN_NO_MSG');
    }
    filtersSetInUI.push({ operator: 'in', property: 'processingStatus', value: processingStatus.map((e) => e!.toString()) });

    // per default we filter out dialogs related to Einreichung_NO_FT for all internal users except operations
    if (isCurrentUserInternal && !isCurrentUserOperations) {
      filtersSetInUI.push({ operator: 'ne', property: 'assignedBusinessTopic', value: ['@ISNULL'] });
    }

    return this.dialogDAO
      .getAllDialog(filtersSetInUI, {
        pageOffset: paginationState.pageIndex * paginationState.pageSize,
        pageSize: paginationState.pageSize,
      })
      .pipe(
        switchMap((dialogs) => {
          return forkJoin({
            dialogs: of(dialogs),
            unreadDialogIds: this.dialogDAO.getUnreadDialogIds(),
          });
        }),
        tap(({ dialogs, unreadDialogIds }) => {
          const newState = produce(ctx.getState(), (draft) => {
            draft.domain.dialogs = dialogs.data;
            draft.totalLength = dialogs.totalAvailableDataCount ?? undefined;
            draft.domain.unreadDialogIds = unreadDialogIds;
          });
          ctx.setState(newState);
          this.setInitialOverviewLoadingCompleted(ctx);
        }),
        catchError((err) => {
          this.setInitialOverviewLoadingCompleted(ctx);
          throw err;
        })
      );
  }

  @OnAction(Details.Enter)
  detailsEnter(ctx: StateContext<DialogStateModel>, { dialogId }: Details.Enter) {
    ctx.dispatch(new Details.LoadMessages(dialogId));
    this.listenForCurrentDialogDetailsChanges(ctx);
    return this.initializeDomainStateForDetails(ctx, dialogId).pipe(switchMap(() => this.markMessagesAsReadForDialog(dialogId)));
  }

  @OnAction(Details.LoadMessages)
  detailsLoadMessages(ctx: StateContext<DialogStateModel>, { dialogId }: Details.LoadMessages) {
    return this.loadMessages(ctx, dialogId);
  }

  @OnAction(Details.Leave)
  detailsLeave(ctx: StateContext<DialogStateModel>) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.domain.activeDialog = null;
        draft.domain.messages = null;
      })
    );
  }

  @OnAction(Details.ASSIGN)
  assignDialog(ctx: StateContext<DialogStateModel>) {
    // get current user, check its usertype and send assign request
    const currentUserContext = this.store.selectSnapshot(AuthStateSelectors.userContext);
    const isCurrentUserInternal = this.store.selectSnapshot(AuthStateSelectors.isInternalUser);
    const dialogComposite = ctx.getState().domain.activeDialog!.dialog;
    const currentDialog: Dialog = {
      ...dialogComposite,
      assignedBusinessTopic:
        dialogComposite.assignedBusinessTopic ?
          {
            id: dialogComposite.assignedBusinessTopic.id,
            shortDescription: dialogComposite.assignedBusinessTopic?.shortDescriptionDE,
          }
        : null,
    };
    if (isCurrentUserInternal) {
      currentDialog.workingInternalUser = currentUserContext!.id;
      currentDialog.workingExternalUser = undefined;
    } else {
      currentDialog.workingExternalUser = currentUserContext!.id;
      currentDialog.workingInternalUser = undefined;
    }
    return this.dialogDAO
      .saveDialog(currentDialog)
      .pipe(switchMap(() => this.initializeDomainStateForDetails(ctx, currentDialog.dialogId ?? '')));
  }

  @OnAction(Details.UNASSIGN)
  unassignDialog(ctx: StateContext<DialogStateModel>) {
    // get current user, check its usertype and send assign request
    const isCurrentUserInternal = this.store.selectSnapshot(AuthStateSelectors.isInternalUser);
    const dialogComposite = ctx.getState().domain.activeDialog!.dialog;
    const currentDialog: Dialog = {
      ...dialogComposite,
      assignedBusinessTopic:
        dialogComposite.assignedBusinessTopic ?
          {
            id: dialogComposite.assignedBusinessTopic.id,
            shortDescription: dialogComposite.assignedBusinessTopic?.shortDescriptionDE,
          }
        : null,
    };
    if (isCurrentUserInternal) {
      currentDialog.workingInternalUser = '';
    } else {
      currentDialog.workingExternalUser = '';
    }
    return this.dialogDAO
      .saveDialog(currentDialog)
      .pipe(switchMap(() => this.initializeDomainStateForDetails(ctx, currentDialog.dialogId ?? '')));
  }

  @OnAction(DialogModals.SelectBusinessTopic)
  selectBusinessTopic(ctx: StateContext<DialogStateModel>, { selectedBusinessTopic }: DialogModals.SelectBusinessTopic) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.domain.creatingDialog = {
          ...draft.domain.creatingDialog,
          selectedBusinessTopic: selectedBusinessTopic ?? undefined,
        };
      })
    );

    return this.updateCompanyAndBusinessTopicUserCount(ctx);
  }

  @OnAction(DialogModals.SetCompanyLoading)
  setCompanyLoading(ctx: StateContext<DialogStateModel>) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.domain.creatingDialog = {
          ...draft.domain.creatingDialog,
          company: undefined,
          searchCompanyComplete: false,
        };
      })
    );

    return this.updateCompanyAndBusinessTopicUserCount(ctx);
  }

  @OnAction(DialogModals.SearchCompanyByIdentifier)
  searchCompanyByIdentifier(
    ctx: StateContext<DialogStateModel>,
    { companyIdentifierId, value }: DialogModals.SearchCompanyByIdentifier
  ) {
    return this.dialogDAO.getCompanyByIdentifier(companyIdentifierId, value).pipe(
      tap((company) => {
        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.domain.creatingDialog = {
              ...draft.domain.creatingDialog,
              searchCompanyComplete: true,
              company,
            };
          })
        );
      }),
      catchError(() => {
        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.domain.creatingDialog = {
              ...draft.domain.creatingDialog,
              searchCompanyComplete: true,
              company: undefined,
            };
          })
        );
        return of(null);
      }),
      switchMap(() => this.updateCompanyAndBusinessTopicUserCount(ctx))
    );
  }

  @OnAction(DialogModals.GetCompanyIdentifier)
  getCompanyIdentifier(ctx: StateContext<DialogStateModel>, { companyIdentifierId }: DialogModals.GetCompanyIdentifier) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.domain.creatingDialog = {
          ...draft.domain.creatingDialog,
          companyIdentifier: undefined,
        };
      })
    );

    return this.dialogDAO.getCompanyIdentifier(companyIdentifierId).pipe(
      tap((companyIdentifier) => {
        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.domain.creatingDialog = {
              ...draft.domain.creatingDialog,
              companyIdentifier,
            };
          })
        );
      })
    );
  }

  @OnAction(DialogModals.GetAllUserWorkAreasAndBusinessTopics)
  getAllUserWorkAreasAndBusinessTopics(
    ctx: StateContext<DialogStateModel>,
    { restrictedTo }: DialogModals.GetAllUserWorkAreasAndBusinessTopics
  ) {
    const currentUserContext = this.store.selectSnapshot(AuthStateSelectors.userContext);
    return this.dialogDAO.getUserWorkAreasAndBusinessTopics(currentUserContext!, restrictedTo).pipe(
      tap((workAreas) => {
        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.domain.workAreas = workAreas;
          })
        );
      })
    );
  }

  private initializeDomainStateForDetails(ctx: StateContext<DialogStateModel>, dialogId: string) {
    return this.dialogDAO.getDialogById(dialogId).pipe(
      switchMap((dialog) => {
        const userIds = [dialog.changedBy?.id ?? '', dialog.workingExternalUser ?? '', dialog.workingInternalUser ?? ''].filter(
          (e) => e !== ''
        );
        return forkJoin({
          dialog: of(dialog),
          businessTopic: dialog.assignedBusinessTopic ? this.loadBusinessTopicById(dialog.assignedBusinessTopic.id) : of(null),
          users: this.loadUsersById(userIds),
        });
      }),
      tap(({ dialog, businessTopic, users }) => {
        const changedByUser = users.find((user) => user.id === dialog.changedBy?.id);
        const assignedToExternalUser = users.find((user) => user.id === dialog.workingExternalUser);
        const assignedToInternalUser = users.find((user) => user.id === dialog.workingInternalUser);
        const primaryContactParner =
          (businessTopic?.contactPartners?.length ?? 0) > 0 ?
            businessTopic!.contactPartners.find((contactPartner) => contactPartner.isPrimary)
          : undefined;
        ctx.setState(
          produce(ctx.getState(), ({ domain }) => {
            domain.activeDialog = {
              dialog: dialog,
              primaryContactParner: primaryContactParner ?? null,
              changedByUser:
                dialog.changedBy && changedByUser ?
                  {
                    id: dialog.changedBy.id,
                    firstName: changedByUser?.firstName ?? '',
                    lastName: changedByUser?.lastName ?? '',
                    userType: dialog.changedBy.userType,
                  }
                : undefined,
              assignedUserExternal:
                dialog.workingExternalUser ?
                  {
                    id: dialog.workingExternalUser,
                    firstName: assignedToExternalUser?.firstName ?? undefined,
                    lastName: assignedToExternalUser?.lastName ?? undefined,
                    deleted: assignedToExternalUser === undefined,
                  }
                : null,
              assignedUserInternal:
                dialog.workingInternalUser ?
                  {
                    id: dialog.workingInternalUser,
                    firstName: assignedToInternalUser?.firstName ?? undefined,
                    lastName: assignedToInternalUser?.lastName ?? undefined,
                    deleted: assignedToInternalUser === undefined,
                  }
                : null,
            };
          })
        );
      })
    );
  }

  private loadMessages(ctx: StateContext<DialogStateModel>, dialogId: string) {
    return this.dialogDAO.getMessagesByDialogId(dialogId).pipe(
      switchMap((messages) => {
        const userIds = messages.map((msg) => msg.createdBy ?? '').filter((e) => e !== '');
        return forkJoin({ messages: of(messages), users: this.loadUsersById(userIds) });
      }),
      tap(({ messages }) => {
        const currentUserContext = this.store.selectSnapshot(AuthStateSelectors.userContext);
        const oldMessagesFromCurrentUser =
          Array.from(ctx.getState().domain.messages?.values() ?? [])
            .reduce((accumulator, value) => accumulator.concat(value), [])
            .filter((msg) => msg.createdBy === currentUserContext?.id) ?? null;
        const newMessagesFromCurrentUser = messages.filter((msg) => msg.createdBy === currentUserContext?.id) ?? [];
        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.ui.dialogDetails.messageSentSuccessful = oldMessagesFromCurrentUser.length != newMessagesFromCurrentUser.length;
          })
        );
      }),
      tap(({ messages, users }) => {
        const groupedMessages = new Map<string, Array<DialogMessage>>();
        messages.forEach((msg) => {
          const date = msg.createdAt!.substring(0, 10);
          if (!groupedMessages.has(date)) {
            groupedMessages.set(date, new Array<DialogMessage>());
          }
          groupedMessages.get(date)?.push(msg);
        });
        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.domain.messages = groupedMessages;
            draft.domain.messageUsers = users;
            draft.ui.dialogDetails.messageIsLoading = false;
          })
        );
      }),
      catchError((err) => {
        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.ui.dialogDetails.messageIsLoading = false;
          })
        );
        throw err;
      })
    );
  }

  private updateCompanyAndBusinessTopicUserCount(ctx: StateContext<DialogStateModel>) {
    const companyId = ctx.getState().domain.creatingDialog?.company?.businessKey?.nextId;
    const businessTopicId = ctx.getState().domain.creatingDialog?.selectedBusinessTopic?.id;

    if (!companyId || !businessTopicId) {
      ctx.setState(
        produce(ctx.getState(), (draft) => {
          draft.domain.creatingDialog = {
            ...draft.domain.creatingDialog,
            companyAndBusinessTopicUserCount: undefined,
          };
        })
      );
      return of();
    }

    return this.dialogDAO.getCompanyAndBusinessTopicUserCount(companyId, businessTopicId).pipe(
      tap((count) => {
        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.domain.creatingDialog = {
              ...draft.domain.creatingDialog,
              companyAndBusinessTopicUserCount: count ?? 0,
            };
          })
        );
      }),
      catchError(() => of(null))
    );
  }

  loadCompanyById(id: UniqueIDString<'Company'>): Observable<Company | null> {
    return this.dialogDAO.getCompanyById(id);
  }

  loadUsersById(userIds: Array<UniqueIDString<'User'>>): Observable<Array<User>> {
    return this.dialogDAO.getUsersById(userIds);
  }

  loadBusinessTopicById(businessTopicId: UniqueIDString<'BusinessTopic'>): Observable<BusinessTopic | null> {
    return this.dialogDAO.getBusinessTopicById(businessTopicId);
  }

  @OnAction(Overview.Pagination.Set)
  setPagination(ctx: StateContext<DialogStateModel>, paginationParams: Overview.Pagination.Set) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.pagination.pageIndex = paginationParams.pageIndex;
        draft.pagination.pageSize = paginationParams.pageSize;
      })
    );
    ctx.dispatch(new Overview.GetDialogs());
  }

  @OnAction(Overview.Pagination.Reset)
  resetPagination(ctx: StateContext<DialogStateModel>) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.pagination.pageIndex = 0;
      })
    );
    ctx.dispatch(new Overview.GetDialogs());
  }

  @OnAction(Overview.ToggleAutoRefresh)
  toggleAutoRefresh(ctx: StateContext<DialogStateModel>) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.ui.dialogOverview.isAutoRefreshActive = !draft.ui.dialogOverview.isAutoRefreshActive;
      })
    );
  }

  @OnAction(Details.SendMessage)
  sendMessage(ctx: StateContext<DialogStateModel>, { dialogId, text }: Details.SendMessage) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.ui.dialogDetails.messageSentSuccessful = false;
        draft.ui.dialogDetails.messageIsLoading = true;
      })
    );

    const msg: DialogMessage = {
      dialogId: dialogId,
      status: 'LIVE',
      text: text,
      attachments: [],
    };
    return this.dialogDAO.sendMessage(msg).pipe(
      catchError((err: ErrorResponse) => {
        const problems = err.errors as ValidationErrors;

        if (['INPUT_VALIDATION'].includes(err.type)) {
          ctx.setState(
            produce(ctx.getState(), (draft) => {
              draft.ui.dialogDetails.validationErrors = problems;
              draft.ui.dialogDetails.messageIsLoading = false;
            })
          );
        }

        throw err;
      })
    );
  }

  @OnAction(EXTERNAL.GetDialogsForSubmission)
  getDialogsForSubmission(ctx: StateContext<DialogStateModel>, { correlationIds }: EXTERNAL.GetDialogsForSubmission) {
    const hasDialogPermission = this.store.selectSnapshot(FeatureToggleSelectors.featureToggles).get('DIALOG');
    if (hasDialogPermission == undefined || hasDialogPermission === false) {
      return;
    }
    const filterSetting = new Array<FilterSetting<Dialog>>();
    filterSetting.push({ operator: 'in', property: 'relationResource.referenceCorrelationId', value: correlationIds });

    return this.dialogDAO.getAllDialog(filterSetting).pipe(
      switchMap((dialogs) => {
        return forkJoin({
          dialogs: of(dialogs),
          unreadDialogIds: this.dialogDAO.getUnreadDialogIds(),
        });
      }),
      tap(({ dialogs, unreadDialogIds }) => {
        const newState = produce(ctx.getState(), (draft) => {
          draft.domain.external.submission.dialogs = dialogs.data;
          draft.domain.external.submission.unreadDialogIds = unreadDialogIds;
        });
        ctx.setState(newState);
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @OnAction(EXTERNAL.GetDialogsForProvision)
  getDialogsForProvision(ctx: StateContext<DialogStateModel>, { provisionIds }: EXTERNAL.GetDialogsForProvision) {
    const hasDialogPermission = this.store.selectSnapshot(FeatureToggleSelectors.featureToggles).get('DIALOG');
    if (hasDialogPermission == undefined || hasDialogPermission === false) {
      return;
    }
    const filterSetting = new Array<FilterSetting<Dialog>>();
    filterSetting.push({ operator: 'in', property: 'relationResource.reference_BK_Id', value: provisionIds });

    return this.dialogDAO.getAllDialog(filterSetting).pipe(
      switchMap((dialogs) => {
        return forkJoin({
          dialogs: of(dialogs),
          unreadDialogIds: this.dialogDAO.getUnreadDialogIds(),
        });
      }),
      tap(({ dialogs, unreadDialogIds }) => {
        const newState = produce(ctx.getState(), (draft) => {
          draft.domain.external.provision.dialogs = dialogs.data;
          draft.domain.external.provision.unreadDialogIds = unreadDialogIds;
        });
        ctx.setState(newState);
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @OnAction(DialogModals.NewDialogWithoutContext)
  openCreateDialogWithoutContext(ctx: StateContext<DialogStateModel>) {
    const userContext = this.store.selectSnapshot(AuthStateSelectors.userContext);
    if (userContext?.isOperationsUser || INTERNAL_USER_TYPE_LIST.includes(userContext!.userType)) {
      return this.openCreateDialogModalForInternalUserWithoutContext(ctx);
    } else {
      return this.openCreateDialogModalForExternalUserWithoutContext(ctx);
    }
  }

  @OnAction(DialogModals.NewDialogForSubmission)
  openCreateDailogForSubmission(
    ctx: StateContext<DialogStateModel>,
    { submissionId, fileName, businessTopicId }: DialogModals.NewDialogForSubmission
  ) {
    const isUserInternal = this.store.selectSnapshot(AuthStateSelectors.isInternalUser);
    if (businessTopicId != undefined) {
      ctx.dispatch(new DialogModals.GetAllUserWorkAreasAndBusinessTopics([businessTopicId]));
    } else if (isUserInternal) {
      console.log('Internal user are not allowed to start this type of dialog');
      return;
    }

    const dialogData: CreateDialogWithContextInitializationData = {
      referencedEntityId: submissionId,
      fileName: fileName ?? '',
      withBusinessTopic: businessTopicId !== undefined,
    };
    return this.zone.runGuarded(() => {
      return this.matDialog
        .open(CreateDialogWithContextComponent, {
          data: dialogData,
        })
        .afterClosed()
        .pipe(
          switchMap((creationData) => {
            if (creationData == null || creationData === '') {
              // User cancelled the creation
              return of(null);
            }
            if (!businessTopicId) {
              return this.createDialogForSubmissionWithoutBusinessTopic(
                creationData as unknown as CreateDialogData,
                submissionId
              );
            } else {
              return this.createDialogForSubmission(creationData as unknown as CreateDialogData, submissionId, businessTopicId);
            }
          }),
          tap((dialogId) => {
            if (dialogId !== null) {
              ctx.dispatch(new Overview.GetDialogs());
              this.snackbarService.openSnackBar('SUCCESS', 'DIALOG.CREATE_MODAL.CREATE_SUCCESS');
              ctx.dispatch(new Navigate([RoutesConstants.dialogRelative, dialogId]));
            }
          }),
          catchError((error) => {
            console.log('Failed to create dialog: ', error);
            this.snackbarService.openSnackBar('ERROR', 'DIALOG.CREATE_MODAL.CREATE_FAILED');
            return of(null);
          })
        );
    });
  }

  @OnAction(DialogModals.NewDialogForProvision)
  openCreateDailogForProvision(
    ctx: StateContext<DialogStateModel>,
    { provisionId, fileName, businessTopicId }: DialogModals.NewDialogForProvision
  ) {
    ctx.dispatch(new DialogModals.GetAllUserWorkAreasAndBusinessTopics([businessTopicId]));
    const dialogData: CreateDialogWithContextInitializationData = {
      referencedEntityId: provisionId,
      fileName: fileName ?? '',
      withBusinessTopic: true,
    };
    return this.zone.runGuarded(() => {
      return this.matDialog
        .open(CreateDialogWithContextComponent, {
          data: dialogData,
        })
        .afterClosed()
        .pipe(
          switchMap((creationData) => {
            if (creationData == null || creationData === '') {
              // User cancelled the creation
              return of(null);
            }
            return this.createDialogForProvision(creationData as unknown as CreateDialogData, provisionId, businessTopicId);
          }),
          tap((dialogId) => {
            if (dialogId !== null) {
              ctx.dispatch(new Overview.GetDialogs());
              this.snackbarService.openSnackBar('SUCCESS', 'DIALOG.CREATE_MODAL.CREATE_SUCCESS');
              ctx.dispatch(new Navigate([RoutesConstants.dialogRelative, dialogId]));
            }
          }),
          catchError((error) => {
            console.log('Failed to create dialog: ', error);
            this.snackbarService.openSnackBar('ERROR', 'DIALOG.CREATE_MODAL.CREATE_FAILED');
            return of(null);
          })
        );
    });
  }

  private openCreateDialogModalForInternalUserWithoutContext(ctx: StateContext<DialogStateModel>) {
    ctx.dispatch(new DialogModals.GetAllUserWorkAreasAndBusinessTopics());

    return this.zone.runGuarded(() => {
      return this.matDialog
        .open(CreateDialogNextOperationsComponent, {
          data: { relationResourceType: 'NOT_RELATED' },
        })
        .afterClosed()
        .pipe(
          switchMap((creationData) => {
            return creationData != null && creationData !== '' ?
                this.createDialogByInternalUserWithoutContext(creationData as unknown as CreateDialogData)
              : of(null);
          }),
          tap((dialogId) => {
            if (dialogId !== null) {
              ctx.dispatch(new Overview.GetDialogs());
              this.snackbarService.openSnackBar('SUCCESS', 'DIALOG.CREATE_MODAL.CREATE_SUCCESS');
              ctx.dispatch(new Navigate([RoutesConstants.dialogRelative, dialogId]));
            }
          }),
          catchError((error) => {
            console.log('Failed to create dialog:', error);
            this.snackbarService.openSnackBar('ERROR', 'DIALOG.CREATE_MODAL.CREATE_FAILED');
            return of(null);
          })
        );
    });
  }

  private openCreateDialogModalForExternalUserWithoutContext(ctx: StateContext<DialogStateModel>) {
    ctx.dispatch(new DialogModals.GetAllUserWorkAreasAndBusinessTopics());

    return this.zone.runGuarded(() => {
      return this.matDialog
        .open(CreateDialogExternalUserComponent, {
          data: { relationResourceType: 'NOT_RELATED' },
        })
        .afterClosed()
        .pipe(
          switchMap((creationData) => {
            return creationData != null && creationData !== '' ?
                this.createDialogByExternalUserWithoutContext(creationData as unknown as CreateDialogData)
              : of(null);
          }),
          tap((dialogId) => {
            if (dialogId !== null) {
              ctx.dispatch(new Overview.GetDialogs());
              this.snackbarService.openSnackBar('SUCCESS', 'DIALOG.CREATE_MODAL.CREATE_SUCCESS');
              ctx.dispatch(new Navigate([RoutesConstants.dialogRelative, dialogId]));
            }
          }),
          catchError((error) => {
            console.log('Failed to create dialog', error);
            this.snackbarService.openSnackBar('ERROR', 'DIALOG.CREATE_MODAL.CREATE_FAILED');
            return of(null);
          })
        );
    });
  }

  private createDialogByInternalUserWithoutContext(creationData: CreateDialogData) {
    const dialogToCreate: Dialog = {
      dialogId: null,
      relationResource: {
        relationResourceType: 'NOT_RELATED',
      },
      assignedBusinessTopic: { id: creationData.businessTopic!.id, shortDescription: '' },
      companyIdentifier: {
        id: creationData.companyIdentifierEntry!.id,
        shortDescription: '',
      },
      assignedToCompany: {
        nextId: '',
        companyIdentifierEntry: creationData.companyIdentifierEntry!.entry,
      },
      subject: creationData.subject,
      label: [],
      status: 'LIVE',
    };
    return this.dialogDAO.createDialog(dialogToCreate);
  }

  private createDialogByExternalUserWithoutContext(creationData: CreateDialogData) {
    const dialogToCreate: Dialog = {
      dialogId: null,
      relationResource: {
        relationResourceType: 'NOT_RELATED',
      },
      assignedBusinessTopic: { id: creationData.businessTopic!.id, shortDescription: '' },
      subject: creationData.subject,
      label: [],
      status: 'LIVE',
    };
    return this.dialogDAO.createDialog(dialogToCreate);
  }

  private createDialogForSubmission(
    creationData: CreateDialogData,
    submissionId: UniqueIDString<'Submission'>,
    businessTopicId: UniqueIDString<'BusinessTopic'>
  ) {
    const dialogToCreate: Dialog = {
      dialogId: null,
      relationResource: {
        relationResourceType: 'EINREICHUNG',
        referenceCorrelationId: submissionId,
      },
      assignedBusinessTopic: { id: businessTopicId, shortDescription: '' },
      subject: creationData.subject,
      label: [],
      status: 'LIVE',
    };
    return this.dialogDAO.createDialog(dialogToCreate);
  }

  private createDialogForSubmissionWithoutBusinessTopic(
    creationData: CreateDialogData,
    submissionId: UniqueIDString<'Submission'>
  ) {
    const dialogToCreate: Dialog = {
      dialogId: null,
      relationResource: {
        relationResourceType: 'EINREICHUNG_NO_FT',
        referenceCorrelationId: submissionId,
      },
      assignedBusinessTopic: null,
      subject: creationData.subject,
      label: [],
      status: 'LIVE',
    };
    return this.dialogDAO.createDialog(dialogToCreate);
  }

  private createDialogForProvision(
    creationData: CreateDialogData,
    provisionId: UniqueIDString<'Provision'>,
    businessTopicId: UniqueIDString<'BusinessTopic'>
  ) {
    const dialogToCreate: Dialog = {
      dialogId: null,
      relationResource: {
        relationResourceType: 'BEREITSTELLUNG',
        reference_BK_Id: provisionId,
      },
      assignedBusinessTopic: { id: businessTopicId, shortDescription: '' },
      subject: creationData.subject,
      label: [],
      status: 'LIVE',
    };
    return this.dialogDAO.createDialog(dialogToCreate);
  }

  private setInitialOverviewLoadingCompleted(ctx: StateContext<DialogStateModel>) {
    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.ui.dialogOverview.isOverviewInitialLoadCompleted = true;
      })
    );
  }

  private startSSEListener(ctx: StateContext<DialogStateModel>) {
    merge(this.sseService.dialogNotification$, this.sseService.dialogMessageNotification$)
      .pipe(
        map((notification) => {
          let dialogId = null;
          if (notification.notificationResourceAPI.boundedContextResource === 'DIALOG') {
            dialogId = notification.notificationResourceAPI.businessKey;
          } else if (notification.notificationResourceAPI.boundedContextResource === 'DIALOG_MESSAGE') {
            dialogId = notification.businessKeyVOs.find((vos) => vos.boundedCtxResource === 'DIALOG')?.businessKey ?? '';
          }
          return dialogId;
        }),
        bufferTime(3000),
        takeUntil(this.actions$.pipe(ofAction(Overview.Leave)))
      )
      .subscribe((dialogIds) => {
        if (dialogIds.length > 0) {
          console.log('dialog.state: sse received');
          // check, if dialog is from interest
          let dialogs = ctx.getState().domain.external.submission.dialogs;
          if (dialogs.some((d) => dialogIds.includes(d.dialogId))) {
            ctx.dispatch(
              new EXTERNAL.GetDialogsForSubmission(dialogs.map((dialog) => dialog.relationResource.referenceCorrelationId ?? ''))
            );
          }
          dialogs = ctx.getState().domain.external.provision.dialogs;
          if (dialogs.some((d) => dialogIds.includes(d.dialogId))) {
            ctx.dispatch(
              new EXTERNAL.GetDialogsForProvision(dialogs.map((dialog) => dialog.relationResource.reference_BK_Id ?? ''))
            );
          }
        }
      });
  }

  private listenForNotificationEventsImportantForOverview(ctx: StateContext<DialogStateModel>) {
    // dialog create/update AND dialog message creations are valid triggers to refresh the overview page:
    // Reasons: Due date updates, dialog gets visible as a result of first message, unread message status, ...
    merge(
      this.sseService.dialogNotification$,
      this.sseService.dialogMessageNotification$,
      interval(DIALOG_POLLING_INTERVAL_MS).pipe(startWith(null))
    )
      .pipe(
        combineLatestWith(this.store.select(DialogSelectors.isAutoRefreshActive)),
        takeUntil(this.actions$.pipe(ofAction(Overview.Leave)))
      )
      .subscribe(([_, isAutoRefreshActive]) => {
        if (!isAutoRefreshActive) {
          return;
        }
        ctx.dispatch(new Overview.GetDialogs());
      });
  }

  private listenForCurrentDialogDetailsChanges(ctx: StateContext<DialogStateModel>) {
    merge(
      this.sseService.dialogNotification$.pipe(
        filter((notification) => {
          if (notification.notificationResourceAPI.businessKey !== (ctx.getState().domain.activeDialog?.dialog.dialogId ?? '')) {
            return false;
          }
          return true;
        }),
        takeUntil(this.actions$.pipe(ofAction(Overview.Leave)))
      ),
      interval(DIALOG_POLLING_INTERVAL_MS).pipe(startWith(null))
    ).subscribe(() => {
      const dialogId = ctx.getState().domain.activeDialog?.dialog.dialogId;
      if (dialogId != undefined) {
        ctx.dispatch(new Details.LoadMessages(dialogId));
        this.initializeDomainStateForDetails(ctx, dialogId).pipe(take(1)).subscribe();
      }
    });

    this.sseService.dialogMessageNotification$
      .pipe(
        filter((notification) => {
          if (!notification.businessKeyVOs) {
            return false;
          }
          return notification.businessKeyVOs
            .map((bk) => bk.businessKey)
            .includes(ctx.getState().domain.activeDialog?.dialog.dialogId ?? '');
        }),
        switchMap(() => this.markMessagesAsReadForDialog(ctx.getState().domain.activeDialog!.dialog.dialogId!)),
        takeUntil(this.actions$.pipe(ofAction(Details.Leave)))
      )
      .subscribe(() => {
        const dialogId = ctx.getState().domain.activeDialog?.dialog.dialogId;
        if (dialogId != undefined) {
          ctx.dispatch(new Details.LoadMessages(dialogId));
        }
      });
  }

  private markMessagesAsReadForDialog(dialogId: string) {
    return this.dialogDAO.markMessagesAsReadForDialog(dialogId);
  }
}
