import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { LanguageService } from '@core/services/language.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { WorkArea } from '@shared/api/work-area/work-area.types';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { FilterTextComponent } from '@shared/components/filter-text/filter-text.component';
import { InfoPanelComponent } from '@shared/components/info-panel/info-panel.component';
import { BusinessTopic } from 'src/app/work-area/business-topic/business-topic.types';
import { DialogModals } from '../../store/dialog.actions';
import { DialogSelectors } from '../../store/dialog.selectors';
import { CreateDialogData } from '../create-dialog.model';

@Component({
  selector: 'nxt-create-dialog-next-operations',
  templateUrl: './create-dialog-next-operations.component.html',
  styleUrls: ['./create-dialog-next-operations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogComponent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    MatError,
    InfoPanelComponent,
    FilterTextComponent,
    MatCheckbox,
    MatInput,
    MatHint,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateDialogNextOperationsComponent {
  private dialogRef = inject(MatDialogRef<CreateDialogNextOperationsComponent>);
  private store = inject(Store);
  private translateService = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);
  currentLanguage = inject(LanguageService).currentLanguage;

  formGroup = new FormGroup({
    workArea: new FormControl<WorkArea | null>(null, Validators.required),
    businessTopic: new FormControl<BusinessTopic | null>({ value: null, disabled: true }, Validators.required),
    companyIdentifierEntry: new FormControl<string | null>({ value: null, disabled: true }, Validators.required),
    isAllCompanies: new FormControl<boolean>({ value: false, disabled: true }),
    subject: new FormControl<string | null>(null, Validators.required),
  });

  workAreas = toSignal(this.store.select(DialogSelectors.dialogWorkAreasAndBusinessTopics));
  companyIdentifier = toSignal(this.store.select(DialogSelectors.companyIdentifier));
  loadingComplete = toSignal(this.store.select(DialogSelectors.searchCompanyComplete));
  foundCompany = toSignal(this.store.select(DialogSelectors.searchedCompany));
  noCompanyFound = toSignal(this.store.select(DialogSelectors.noCompanyFound));
  selectedWorkArea = toSignal(this.formGroup.controls.workArea.valueChanges);
  selectedBusinessTopic = toSignal(this.formGroup.controls.businessTopic.valueChanges);
  enteredCompanyIdentifierEntry = toSignal(this.formGroup.controls.companyIdentifierEntry.valueChanges);
  userCount = toSignal(this.store.select(DialogSelectors.companyAndBusinessTopicUserCount));
  displayUserCount = computed(() => this.userCount() !== undefined);
  isCompanyIdentifierEntryDisabled = signal(true);

  companyIdentifierEntryError = signal('');

  constructor() {
    effect(this.subscribeToWorkAreaChanges, { allowSignalWrites: true });
    effect(this.subscribeToBusinessTopicChanges, { allowSignalWrites: true });
    effect(this.subscribeToCompanyIdentifierEntryChanges, { allowSignalWrites: true });
    effect(this.subscribeToCompanySearchResponse, { allowSignalWrites: true });
  }

  onCompanyIdentifierEntryChangedInstant(userInput: string) {
    this.store.dispatch(new DialogModals.SetCompanyLoading());
    if (this.enteredCompanyIdentifierEntry()) {
      this.companyIdentifierEntryError.set('');
    }

    this.formGroup.controls.companyIdentifierEntry.setValue(userInput);
  }

  onCompanyIdentifierEntryChanged() {
    const selectedWorkArea = this.selectedWorkArea();
    const enteredCompanyIdentifierEntry = this.formGroup.controls.companyIdentifierEntry.value;

    if (!selectedWorkArea || !enteredCompanyIdentifierEntry) {
      return;
    }

    this.store.dispatch(
      new DialogModals.SearchCompanyByIdentifier(selectedWorkArea.companyIdentifierId, enteredCompanyIdentifierEntry)
    );
  }

  onSave() {
    this.dialogRef.close(this.createDialogData());
  }

  /**
   * Compares two workAreas or businessTopics based on their unique id to check if they are the same.
   * This is used as comparison method for mat-select as large workArea objects cause issues otherwise
   */
  compareSelection(o1?: WorkArea | BusinessTopic | null, o2?: WorkArea | BusinessTopic | null) {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }

  onCompanyIdentifierTouch() {
    this.formGroup.controls.companyIdentifierEntry.markAsTouched();
    this.setCompanyIdentifierEntryError();
  }

  onCompanyIdentifierBlur() {
    this.formGroup.controls.companyIdentifierEntry.markAsDirty();
    if (!this.enteredCompanyIdentifierEntry()) {
      this.formGroup.controls.companyIdentifierEntry.setErrors({ required: true });
    }
    this.setCompanyIdentifierEntryError();
  }

  private createDialogData(): CreateDialogData | void {
    const selectedWorkArea = this.selectedWorkArea();
    const selectedBusinessTopic = this.formGroup.controls.businessTopic.value;
    const enteredCompanyIdentifierEntry = this.formGroup.controls.companyIdentifierEntry.value;
    const subject = this.formGroup.controls.subject.value;

    if (!selectedWorkArea || !selectedBusinessTopic || !enteredCompanyIdentifierEntry || !subject) {
      return;
    }

    return {
      workArea: selectedWorkArea,
      businessTopic: selectedBusinessTopic,
      companyIdentifierEntry: {
        id: selectedWorkArea.companyIdentifierId,
        entry: enteredCompanyIdentifierEntry,
      },
      subject: subject,
    };
  }

  private subscribeToWorkAreaChanges = () => {
    this.formGroup.controls.businessTopic.setValue(null);

    const selectedWorkArea = this.selectedWorkArea();

    if (selectedWorkArea) {
      this.store.dispatch(new DialogModals.GetCompanyIdentifier(selectedWorkArea.companyIdentifierId));
    }

    if (selectedWorkArea && this.formGroup.controls.businessTopic.disabled) {
      this.formGroup.controls.businessTopic.enable({ onlySelf: true, emitEvent: false });
    }
  };

  private subscribeToBusinessTopicChanges = () => {
    if (!this.selectedBusinessTopic()) {
      this.formGroup.controls.companyIdentifierEntry.setValue(null);
      this.cdr.detectChanges(); // needs to be trigger before disabling, otherwise emptying input will not occur
      this.formGroup.controls.companyIdentifierEntry.disable({ onlySelf: true, emitEvent: false });
      this.isCompanyIdentifierEntryDisabled.set(true);
    } else {
      this.formGroup.controls.companyIdentifierEntry.enable({ onlySelf: true, emitEvent: false });
      this.isCompanyIdentifierEntryDisabled.set(false);
    }

    this.store.dispatch(new DialogModals.SelectBusinessTopic(this.selectedBusinessTopic()));
  };

  private subscribeToCompanyIdentifierEntryChanges = () => {
    this.enteredCompanyIdentifierEntry();
    this.setCompanyIdentifierEntryError();
  };

  private subscribeToCompanySearchResponse = () => {
    this.foundCompany();
    this.setCompanyIdentifierEntryError();
  };

  private setCompanyIdentifierEntryError() {
    if (this.formGroup.controls.companyIdentifierEntry.untouched) {
      this.formGroup.controls.companyIdentifierEntry.setErrors({ identifier: true });
      return this.companyIdentifierEntryError.set('');
    }

    if (
      this.formGroup.controls.companyIdentifierEntry.getError('required') &&
      this.formGroup.controls.companyIdentifierEntry.dirty
    ) {
      return this.companyIdentifierEntryError.set(this.translateService.instant('PROBLEMCODES.20015') as string);
    }

    if (this.noCompanyFound()) {
      const identifierName = this.companyIdentifier()?.name;

      this.formGroup.controls.companyIdentifierEntry.setErrors({ identifier: true });
      return this.companyIdentifierEntryError.set(
        identifierName ?
          (this.translateService.instant('DIALOG.CREATE_MODAL.NEXT_OPERATIONS_MODAL.ERROR.UNKNOWN_IDENTIFIER', {
            identifierName: identifierName,
          }) as string)
        : ''
      );
    }

    if (!this.formGroup.controls.companyIdentifierEntry.errors) {
      this.formGroup.controls.companyIdentifierEntry.setErrors(null);
      return this.companyIdentifierEntryError.set('');
    }

    this.formGroup.controls.companyIdentifierEntry.setErrors(null);
    return this.companyIdentifierEntryError.set('');
  }
}
