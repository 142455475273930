import { inject, Injectable } from '@angular/core';
import { ProvisionGetDTO, ProvisionPatchDTO } from './provision.dto';
import { ProvisionRest } from './provision.rest';
import { getCleanObject } from '../../functions';
import { Provision } from '@shared/api/provision/provision.types';
import { RestBaseMapper } from '../base/rest-base.mapper';
import { FullPropertyPathOf, PropertyPathForNExtQueryEngine, RecursiveConcrete } from '@shared/types';

@Injectable({ providedIn: 'root' })
export class ProvisionMapper extends RestBaseMapper<Provision, ProvisionGetDTO> {
  private readonly provisionRestAPI = inject(ProvisionRest);

  override toClientModel(provisionGetDTO: ProvisionGetDTO): Provision {
    return {
      id: provisionGetDTO.businessKey.bereitstellungId,
      businessTopicID: provisionGetDTO.fachthemaBusinessKey.fKey,
      date: provisionGetDTO.datum,
      description: provisionGetDTO.description,
      downloadConfirmation: provisionGetDTO.downloadBestaetigung,
      downloadCount: provisionGetDTO.downloadCount,
      fileName: provisionGetDTO.dateiname,
      originalFileName: provisionGetDTO.originalDateiname,
      originalFileNameWithoutPrefix: provisionGetDTO.originalDateinameOhnePrefix,
      nextExtraNetPrefix: provisionGetDTO.nextExtraNetPrefix,
      fileSizeByte: provisionGetDTO.dateiGroesseByte,
      fileKind: provisionGetDTO.dateiArt,
      isTechnicalRecipient: provisionGetDTO.istTechnischerEmpfaenger,
      creatorCompanyId: provisionGetDTO.techEinreicherUnternehmenBusinessKey.nextId,
      fileData: {
        name: provisionGetDTO.originalDateinameOhnePrefix,
        link:
          provisionGetDTO.downloadBestaetigung ? undefined : (
            `${this.provisionRestAPI.resourceUrl}/${provisionGetDTO.businessKey.bereitstellungId}/file`
          ),
      },
      provisionOwner: provisionGetDTO.bereitstellungOwner,
      // TODO: As per PO Discussion recipient is not part of the model currently but still needed in the view
      recipient: '',
      timeOfDelivery: provisionGetDTO.bereitstellungsZeitpunkt,
      isAtLeastDownloadedOnceByExternalUser: provisionGetDTO.atLeastDownloadedOnceByExternalUser ?? false,
      downloadHistoryList:
        provisionGetDTO.downloadHistoryList?.map((dtoDownloadHistoryListEntry) => {
          return {
            downloadedAt: dtoDownloadHistoryListEntry.downloadedAt,
            userId: dtoDownloadHistoryListEntry.nutzerBusinessKey.userId,
            companyId: dtoDownloadHistoryListEntry.unternehmenBusinessKey.nextId,
          };
        }) ?? [],
      downloadConfirmationUserId: provisionGetDTO.downloadBestaetigungNutzerBusinessKey?.userId,
      downloadConfirmationAt: provisionGetDTO?.downloadBestaetigungAt,
      provisionType: provisionGetDTO.bstTyp,
    };
  }
  override toDTO(provision: Partial<Provision>): ProvisionPatchDTO {
    const repositoryModel: ProvisionPatchDTO = {
      businessKey: {
        bereitstellungId: provision.id!,
      },
      bereitstellungOwner: {
        ownerTyp: provision.provisionOwner?.ownerTyp,
        transitiveOwnerTyp: provision.provisionOwner?.transitiveOwnerTyp,
        reference_BK_Id: provision.provisionOwner?.reference_BK_Id,
        referenceCorrelationId: provision.provisionOwner?.reference_BK_Id,
      } as ProvisionPatchDTO['bereitstellungOwner'],
      fachthemaBusinessKey: {
        fKey: provision.businessTopicID ?? '',
      },
      datum: provision.date,
      dateiArt: provision.fileKind,
      istTechnischerEmpfaenger: provision.isTechnicalRecipient,
      downloadBestaetigung: provision.downloadConfirmation,
      description: provision.description,
    };

    return getCleanObject(repositoryModel);
  }

  override mapPropertyPathsFromClientToDTO(
    property: FullPropertyPathOf<Provision>
  ): PropertyPathForNExtQueryEngine<RecursiveConcrete<ProvisionGetDTO>> {
    switch (property) {
      case 'id':
        return 'businessKey.bereitstellungId';
      case 'businessTopicID':
        return 'fachthemaBusinessKey.fKey';
      case 'date':
        return 'datum';
      case 'description':
        return 'description';
      case 'downloadConfirmation':
        return 'downloadBestaetigung';
      case 'downloadCount':
        return 'downloadCount';
      case 'fileName':
        return 'dateiname';
      case 'originalFileName':
        return 'originalDateiname';
      case 'originalFileNameWithoutPrefix':
        return 'originalDateinameOhnePrefix';
      case 'nextExtraNetPrefix':
        return 'nextExtraNetPrefix';
      case 'fileSizeByte':
        return 'dateiGroesseByte';
      case 'fileKind':
        return 'dateiArt';
      case 'isTechnicalRecipient':
        return 'istTechnischerEmpfaenger';
      case 'creatorCompanyId':
        return 'techEinreicherUnternehmenBusinessKey.nextId';
      case 'provisionOwner.ownerTyp':
        return 'bereitstellungOwner.ownerTyp';
      case 'provisionOwner.transitiveOwnerTyp':
        return 'bereitstellungOwner.transitiveOwnerTyp';
      case 'provisionOwner.reference_BK_Id':
        return 'bereitstellungOwner.reference_BK_Id';
      case 'provisionOwner.referenceCorrelationId':
        return 'bereitstellungOwner.referenceCorrelationId';
      case 'timeOfDelivery':
        return 'bereitstellungsZeitpunkt';
      case 'isAtLeastDownloadedOnceByExternalUser':
        return 'atLeastDownloadedOnceByExternalUser';
      // TODO/IMPROVE : Arrays not supported yet
      // case 'downloadHistoryList':
      //   return '';
      case 'downloadConfirmationUserId':
        return 'downloadBestaetigungNutzerBusinessKey.userId';
      case 'downloadConfirmationAt':
        return 'downloadBestaetigungAt';
      case 'fileData.name':
        return 'originalDateinameOhnePrefix';
      case 'provisionType':
        return 'bstTyp';
      default:
        throw new Error(`Property ${property} not found in mapping.`);
    }
  }
}
