import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { LanguageService } from '@core/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { WorkArea } from '@shared/api/work-area/work-area.types';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { FilterTextComponent } from '@shared/components/filter-text/filter-text.component';
import { InfoPanelComponent } from '@shared/components/info-panel/info-panel.component';
import { BusinessTopic } from 'src/app/work-area/business-topic/business-topic.types';
import { DialogModals } from '../../store/dialog.actions';
import { DialogSelectors } from '../../store/dialog.selectors';
import { CreateDialogData } from '../create-dialog.model';
import { DialogState } from '../../store/dialog.state';

@Component({
  selector: 'nxt-create-dialog-external-user',
  templateUrl: './create-dialog-external-user.component.html',
  styleUrls: ['./create-dialog-external-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogComponent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    MatError,
    InfoPanelComponent,
    FilterTextComponent,
    MatCheckbox,
    MatInput,
    MatHint,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    TranslateModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateDialogExternalUserComponent {
  private dialogRef = inject(MatDialogRef<CreateDialogExternalUserComponent>);
  private store = inject(Store);
  currentLanguage = inject(LanguageService).currentLanguage;

  formGroup = new FormGroup({
    workArea: new FormControl<WorkArea | null>(null, Validators.required),
    businessTopic: new FormControl<BusinessTopic | null>({ value: null, disabled: true }, Validators.required),
    subject: new FormControl<string | null>(null, Validators.required),
  });

  workAreas = toSignal(this.store.select(DialogSelectors.dialogWorkAreasAndBusinessTopics));
  selectedWorkArea = toSignal(this.formGroup.controls.workArea.valueChanges);
  selectedBusinessTopic = toSignal(this.formGroup.controls.businessTopic.valueChanges);

  constructor() {
    effect(this.subscribeToWorkAreaChanges, { allowSignalWrites: true });
    effect(this.subscribeToBusinessTopicChanges, { allowSignalWrites: true });
  }

  onSave() {
    this.dialogRef.close(this.createDialogData());
  }

  /**
   * Compares two workAreas or businessTopics based on their unique id to check if they are the same.
   * This is used as comparison method for mat-select as large workArea objects cause issues otherwise
   */
  compareSelection(o1?: WorkArea | BusinessTopic | null, o2?: WorkArea | BusinessTopic | null) {
    if (!o1 || !o2) {
      return false;
    }
    return o1.id === o2.id;
  }

  private createDialogData(): CreateDialogData | void {
    const selectedWorkArea = this.selectedWorkArea();
    const selectedBusinessTopic = this.formGroup.controls.businessTopic.value;
    const subject = this.formGroup.controls.subject.value;

    if (!selectedWorkArea || !selectedBusinessTopic || !subject) {
      return;
    }

    return {
      workArea: selectedWorkArea,
      businessTopic: selectedBusinessTopic,
      subject: subject,
    };
  }

  private subscribeToWorkAreaChanges = () => {
    this.formGroup.controls.businessTopic.setValue(null);

    const selectedWorkArea = this.selectedWorkArea();

    if (selectedWorkArea) {
      this.store.dispatch(new DialogModals.GetCompanyIdentifier(selectedWorkArea.companyIdentifierId));
    }

    if (selectedWorkArea && this.formGroup.controls.businessTopic.disabled) {
      this.formGroup.controls.businessTopic.enable({ onlySelf: true, emitEvent: false });
    }
  };

  private subscribeToBusinessTopicChanges = () => {
    this.store.dispatch(new DialogModals.SelectBusinessTopic(this.selectedBusinessTopic()));
  };
}
