import { Injectable, inject } from '@angular/core';
import { DataLeaf } from '../base/composite-and-strategies';
import { DialogGetDTO } from './dialog.dto';
import { DialogRest } from './dialog.rest';
import { DialogMapper } from './dialog.mapper';
import { ReadAllOptions, ReadAllResult, ReadHeaders } from '../base/api.types';
import { Dialog } from './dialog.types';
import { Observable, catchError, map, of } from 'rxjs';
import { UniqueIDString } from '@shared/string-alias-types';
import { getCleanObject } from '@shared/functions';

@Injectable({ providedIn: 'root' })
export class DialogLeaf extends DataLeaf<Dialog, { getModel: DialogGetDTO }> {
  protected override access = inject(DialogRest);
  protected override mapping = inject(DialogMapper);

  override readAllData(
    readAllOptions: ReadAllOptions<Dialog> = {},
    headers: ReadHeaders = {}
  ): Observable<ReadAllResult<Dialog>> {
    return super.readAllData(readAllOptions, headers).pipe(catchError(() => of({ data: [], totalLength: 0 })));
  }

  override read(id: UniqueIDString<'Dialog'>) {
    return this.access.read(id).pipe(map((response) => this.mapping.toClientModel(response)));
  }

  override patch(dialog: Dialog, readHeaders: ReadHeaders = {}) {
    return this.access.patch(getCleanObject(this.mapping.toDTO(dialog)), readHeaders);
  }

  override create(dialog: Dialog) {
    return this.access.createDialog(getCleanObject(this.mapping.toPostDTO(dialog)));
  }

  patchWithoutClean(dialog: Dialog, readHeaders: ReadHeaders = {}) {
    return this.access.patch(this.mapping.toDTO(dialog), readHeaders);
  }

  getUnreadDialogIds() {
    return this.access.getUnreadDialogIds();
  }

  markMessagesAsReadForDialog(id: UniqueIDString<'Dialog'>) {
    return this.access.markMessagesAsReadForDialog(id);
  }
}
