import { UniqueIDString } from '@shared/string-alias-types';
import { BusinessTopic } from 'src/app/work-area/business-topic/business-topic.types';

const FEATURE = 'Dialog';

export namespace Overview {
  const VIEW = 'Overview';

  export class Enter {
    static readonly type = `${FEATURE + VIEW}: Enter component`;
  }

  export class Leave {
    static readonly type = `${FEATURE + VIEW}: Leave component`;
  }

  export class GetDialogs {
    static readonly type = `${FEATURE + VIEW}: Start getting import orders`;
  }

  export namespace Pagination {
    const FUNCTION = 'Pagination';
    export class Set {
      static readonly type = `${FEATURE + VIEW} ${FUNCTION}: Set Pagination`;
      constructor(
        readonly pageSize: number,
        readonly pageIndex: number
      ) {}
    }
    export class Reset {
      static readonly type = `${FEATURE + VIEW} ${FUNCTION}: Reset Pagination`;
    }
  }

  export class ToggleAutoRefresh {
    static readonly type = `${FEATURE + VIEW}: Toggle auto refresh`;
  }
}

export namespace Details {
  const PAGE = 'Details';

  export class Enter {
    static readonly type = `${FEATURE + PAGE}: Enter view`;
    constructor(readonly dialogId: string) {}
  }

  export class Leave {
    static readonly type = `${FEATURE + PAGE}: Leave view`;
    constructor() {}
  }

  export class LoadMessages {
    static readonly type = `${FEATURE + PAGE}: Load messages`;
    constructor(readonly dialogId: string) {}
  }

  export class SendMessage {
    static readonly type = `${FEATURE + PAGE}: Send message`;
    constructor(
      readonly dialogId: string,
      readonly text: string
    ) {}
  }

  export class ASSIGN {
    static readonly type = `${FEATURE + PAGE}: Assign`;
    constructor() {}
  }

  export class UNASSIGN {
    static readonly type = `${FEATURE + PAGE}: Unassign`;
    constructor() {}
  }
}

export namespace EXTERNAL {
  const PAGE = 'EXTERNAL';
  export class GetDialogsForSubmission {
    static readonly type = `${FEATURE + PAGE}: Load dialogs by submission correlation id`;
    constructor(readonly correlationIds: Array<UniqueIDString<'Dialog'>>) {}
  }

  export class GetDialogsForProvision {
    static readonly type = `${FEATURE + PAGE}: Load dialogs by provision id`;
    constructor(readonly provisionIds: Array<UniqueIDString<'Dialog'>>) {}
  }
}

export namespace DialogModals {
  const PAGE = 'DialogModal';
  export class NewDialogWithoutContext {
    static readonly type = `${FEATURE + PAGE}: New Dialog without context`;
    constructor() {}
  }

  export class NewDialogForSubmission {
    static readonly type = `${FEATURE + PAGE}: New Dialog for submission`;
    constructor(
      readonly submissionId: UniqueIDString<'Submission'>,
      readonly fileName?: string,
      readonly businessTopicId?: UniqueIDString<'BusinessTopic'>
    ) {}
  }

  export class NewDialogForProvision {
    static readonly type = `${FEATURE + PAGE}: New Dialog for provision`;
    constructor(
      readonly provisionId: UniqueIDString<'Provision'>,
      readonly fileName: string,
      readonly businessTopicId: UniqueIDString<'BusinessTopic'>
    ) {}
  }

  export class SelectBusinessTopic {
    static readonly type = `${FEATURE + PAGE}: Set selected business topic on user select`;
    constructor(readonly selectedBusinessTopic?: BusinessTopic | null) {}
  }

  export class SetCompanyLoading {
    static readonly type = `${FEATURE + PAGE}: Set company search to pending on user input`;
  }

  export class SearchCompanyByIdentifier {
    static readonly type = `${FEATURE + PAGE}: Search for company by company identifier id and value`;
    constructor(
      readonly companyIdentifierId: UniqueIDString<'CompanyIdentifier'>,
      readonly value: string
    ) {}
  }

  export class GetCompanyIdentifier {
    static readonly type = `${
      FEATURE + PAGE
    }: Get company identifier on work area selection to display name as label in corresponding form field`;
    constructor(readonly companyIdentifierId: UniqueIDString<'CompanyIdentifier'>) {}
  }

  export class GetAllUserWorkAreasAndBusinessTopics {
    static readonly type = `${FEATURE + PAGE}: Get all user work areas and business topics on modal open`;

    constructor(readonly restrictedTo?: Array<UniqueIDString<'BusinessTopic'>>) {}
  }
}
