import { Injectable } from '@angular/core';
import { ApiConstants } from '@core/constants/api.constants';
import { Observable, map, throwError } from 'rxjs';
import { environment } from 'src/build-environments/environment';
import { ReadHeaders } from '../base/api.types';
import { RestAPIAdapter } from '../base/rest-api.adapter';
import { DialogGetDTO } from './dialog.dto';
import { UniqueIDString } from '@shared/string-alias-types';

@Injectable({
  providedIn: 'root',
})
export class DialogRest extends RestAPIAdapter<DialogGetDTO> {
  microServiceCoordinates = {
    baseUri: `${environment.apiBaseUrl}/${ApiConstants.DIALOG_API_PATH}/api`,
    version: 'v1.0.0',
  };
  resourceUrl = `${this.microServiceCoordinates.baseUri}/${this.microServiceCoordinates.version}/${ApiConstants.DIALOG_API_PATH}`;

  createDialog(createDTO: any, readHeaders?: ReadHeaders): Observable<string> {
    return super.create(createDTO, readHeaders).pipe(
      map((response) => {
        if (response.status !== 200 && response.status !== 201) {
          throwError(() => new Error('Dialog creation failed'));
        }
        const id = super.extractIdFromURL(response.headers.get('Location'));
        if (id === undefined || id.trim().length === 0) {
          throwError(() => new Error('No DialogId available'));
        }
        return id!;
      })
    );
  }

  getUnreadDialogIds() {
    return this.httpClient
      .get<
        Array<{ dialogBusinessKey: { dialogId: UniqueIDString<'Dialog'> } }>
      >(`${this.microServiceCoordinates.baseUri}/${this.microServiceCoordinates.version}/dialog-read-users`)
      .pipe(
        map((response) => {
          return response.map((entry) => entry.dialogBusinessKey.dialogId);
        })
      );
  }

  markMessagesAsReadForDialog(id: UniqueIDString<'Dialog'>) {
    return this.httpClient.post<void>(
      `${this.microServiceCoordinates.baseUri}/${this.microServiceCoordinates.version}/dialog-read`,
      {
        dialogBusinessKey: {
          dialogId: id,
        },
      },
      {
        headers: {},
        observe: 'response',
      }
    );
  }
}
