import { Injectable } from '@angular/core';
import { Dialog } from '@shared/api/dialog/dialog.types';
import { UniqueIDString } from '@shared/string-alias-types';
import { FilterSetting, Pageable } from '@shared/types';
import { map, Observable, of, catchError } from 'rxjs';
import { DialogComposite, DialogComposition } from './dialog.composite';
import { CompanyLeaf } from '@shared/api/company/company.leaf';
import { UserLeaf } from '@shared/api/user/user.leaf';
import { DialogMessageLeaf } from '@shared/api/dialog-message/dialog-message.leaf';
import { DialogLeaf } from '@shared/api/dialog/dialog.leaf';
import { WorkAreaLeaf } from '@shared/api/work-area/work-area.leaf';
import { Company } from '@shared/api/company/company.types';
import { ReadAllOptions } from '@shared/api/base/api.types';
import { CompanyIdentifierLeaf } from '@shared/api/company-identifier/company-identifier.leaf';
import { User, UserContext } from '@shared/api/user/user.types';
import { DialogMessage } from '@shared/api/dialog-message/dialog-message.types';
import { BusinessTopicLeaf } from '@shared/api/business-topic/business-topic.leaf';

@Injectable()
export class DialogDAO {
  constructor(
    private dialogComposition: DialogComposition,
    private dialogLeaf: DialogLeaf,
    private dialogMessageLeaf: DialogMessageLeaf,
    private companyLeaf: CompanyLeaf,
    private userLeaf: UserLeaf,
    private workAreaLeaf: WorkAreaLeaf,
    private businessTopicLeaf: BusinessTopicLeaf,
    private companyIdentifierLeaf: CompanyIdentifierLeaf
  ) {}

  getAllDialog(filters: Array<FilterSetting<Dialog>> = [], pageable?: Pageable) {
    let dialogRequest;
    let pagination = undefined;
    if (pageable && pageable.pageSize) {
      pagination = {
        size: pageable?.pageSize,
        offset: pageable?.pageOffset,
      };
    }

    if (filters.length === 0) {
      dialogRequest = this.dialogComposition.readAllData({
        pagination: pagination,
        sortingBy: { order: 'descending', properties: ['createdAt'] },
      });
    } else {
      dialogRequest = this.dialogComposition.readAllData({
        pagination: pagination,
        filtering: {
          concatenationMode: 'and',
          filters: [...this.buildFilterParamsFromUserFilter(filters)],
        },
        sortingBy: { order: 'descending', properties: ['createdAt'] },
      });
    }

    return dialogRequest.pipe(map((result) => ({ data: result.data, totalAvailableDataCount: result.totalLength })));
  }

  getDialogById(dialogId: UniqueIDString<'Dialog'>): Observable<DialogComposite> {
    return this.dialogComposition.readDialogById(dialogId);
  }

  getCompanyById(id: UniqueIDString<'Company'>) {
    return this.companyLeaf.read(id);
  }

  getBusinessTopicById(id: UniqueIDString<'BusinessTopic'>) {
    return this.businessTopicLeaf.read(id);
  }

  getCompanyByIdentifier(companyIdentifierId: UniqueIDString<'CompanyIdentifier'>, value: string) {
    const readAllOptions: ReadAllOptions<Company> = {
      filtering: {
        concatenationMode: 'and',
        filters: [
          {
            attribute: 'identifierEntries[].businessKey.companyIdentifier_BK_key',
            operator: 'eq',
            value: companyIdentifierId,
          },
          {
            attribute: 'identifierEntries[].eintrag',
            operator: 'eq',
            value: value,
          },
        ],
      },
    };

    return this.companyLeaf.readAllData(readAllOptions).pipe(map((result) => result.data[0]));
  }

  getCompanyAndBusinessTopicUserCount(
    companyIdentifierId: UniqueIDString<'CompanyIdentifier'>,
    businessTopicId: UniqueIDString<'BusinessTopic'>
  ) {
    const readAllOptions: ReadAllOptions<User> = {
      filtering: {
        concatenationMode: 'and',
        filters: [
          {
            attribute: 'businessTopicIds',
            operator: 'in',
            value: businessTopicId,
          },
          {
            attribute: 'companyIds',
            operator: 'in',
            value: companyIdentifierId,
          },
        ],
      },
    };

    return this.userLeaf.readAllData(readAllOptions).pipe(map((result) => result.data.length));
  }

  getCompanyIdentifier(companyIdentifierId: UniqueIDString<'CompanyIdentifier'>) {
    return this.companyIdentifierLeaf.read(companyIdentifierId);
  }

  getUserWorkAreasAndBusinessTopics(userContext: UserContext, restrictedTo?: Array<UniqueIDString<'BusinessTopic'>>) {
    let businessTopicIdsToLimit = userContext.businessTopics.map((businessTopic) => businessTopic.id);
    if (restrictedTo) {
      businessTopicIdsToLimit = restrictedTo;
    }
    return this.workAreaLeaf
      .readAllData({
        filtering: {
          concatenationMode: 'and',
          filters: [
            {
              attribute: 'businessTopics[].id',
              operator: 'in',
              value: [...businessTopicIdsToLimit],
            },
          ],
        },
      })
      .pipe(
        map((result) => {
          const workAreas = result.data;
          // filter out business topics the user has no permissions for
          workAreas.forEach((workArea) => {
            workArea.businessTopics = workArea.businessTopics.filter((businessTopic) =>
              businessTopicIdsToLimit.includes(businessTopic.id)
            );
          });
          return workAreas;
        })
      );
  }

  getUsersById(ids: Array<UniqueIDString<'User'>>) {
    if (ids.length === 0) {
      return of([]);
    }
    return this.userLeaf
      .readAllData({
        filtering: {
          concatenationMode: 'or',
          filters: [
            {
              attribute: 'id',
              operator: 'in',
              value: ids.join(','),
            },
          ],
        },
      })
      .pipe(
        map((readAllResult) => readAllResult.data),
        catchError(() => {
          return of([]);
        })
      );
  }

  getMessagesByDialogId(dialogId: UniqueIDString<'DialogMessage'>) {
    return this.dialogMessageLeaf
      .readAllData({
        sortingBy: { order: 'descending', properties: ['createdAt'] },
        filtering: {
          concatenationMode: 'or',
          filters: [
            {
              attribute: 'dialogId',
              operator: 'eq',
              value: dialogId,
            },
          ],
        },
      })
      .pipe(map((readAllResult) => readAllResult.data));
  }

  saveDialog(currentDialog: Dialog) {
    // we cannot clean undefined/null or empty strings. They must be in place due to unassign fields.
    return this.dialogLeaf.patchWithoutClean(currentDialog);
  }

  createDialog(dialogToCreate: Dialog) {
    return this.dialogLeaf.create(dialogToCreate);
  }

  sendMessage(msg: DialogMessage) {
    return this.dialogMessageLeaf.create(msg);
  }

  getUnreadDialogIds() {
    return this.dialogLeaf.getUnreadDialogIds();
  }

  markMessagesAsReadForDialog(dialogId: string) {
    return this.dialogLeaf.markMessagesAsReadForDialog(dialogId);
  }

  private buildFilterParamsFromUserFilter(userFilters: FilterSetting<Dialog>[]) {
    return userFilters.map((filter) => ({
      operator: filter.operator,
      attribute: filter.property,
      value: filter.value,
    }));
  }
}
