import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgSwitch, NgSwitchCase, NgStyle, NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'nxt-dialog-status-icon',
  templateUrl: './dialog-status-icon.component.html',
  styleUrl: './dialog-status-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgClass, MatIcon, NgStyle, NgIf, TranslateModule],
})
export class DialogStatusIconComponent {
  @Input() statusValue!: 'DEADLINE_EXPIRED' | 'UNREAD_MESSAGES' | 'ACTIVE' | 'ARCHIVED' | 'NEW';
  @Input() iconSize = '24px';
  @Input() withLabel = false;
  @Input() cssClass?: string = undefined;

  getIconStyling = () => ({ 'font-size': this.iconSize, width: this.iconSize, height: this.iconSize });
}
