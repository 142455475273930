import { Injectable } from '@angular/core';
import { environment } from '../../../../build-environments/environment';
import { RestAPIAdapter } from '../base/rest-api.adapter';
import { ProvisionGetDTO, ProvisionPatchDTO } from '@shared/api/provision/provision.dto';
import { ApiConstants } from '@core/constants/api.constants';
import { Provision } from '@shared/api/provision/provision.types';

@Injectable({ providedIn: 'root' })
export class ProvisionRest extends RestAPIAdapter<
  ProvisionGetDTO,
  {
    patchModel: ProvisionPatchDTO;
    getModel: ProvisionGetDTO;
  }
> {
  microServiceCoordinates = {
    baseUri: `${environment.apiBaseUrl}/bereitstellung/api`,
    version: 'v1.0.0',
  };
  resourceUrl = `${this.microServiceCoordinates.baseUri}/${this.microServiceCoordinates.version}/${ApiConstants.PROVISION_API_PATH}`;

  patchProvision(form: any) {
    return this.httpClient.patch<Provision>(this.resourceUrl, form);
  }
}
