<div class="modal-container nxt-accent-bg__100">
  <div class="modal-header nxt-accent-800" mat-dialog-title>
    <ng-content select="nxt-modal-header"/>

    <button mat-icon-button mat-dialog-close class="modal-close-btn">
      <mat-icon aria-label="Close">close</mat-icon>
    </button>
  </div>
  <div class="modal-body" mat-dialog-content>
    <div class="modal-content">
      <ng-content select="nxt-modal-body"/>
      <ng-content/>
    </div>
  </div>
  <div class="modal-footer mb-4">
    <ng-content select="nxt-modal-footer"/>
  </div>
</div>
