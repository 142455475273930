import { Injectable } from '@angular/core';
import { SubmissionGetDTO } from '@shared/api/submission/submission.dto';
import { getCleanObject } from '../../functions';
import { DateHelpers } from '@core/helpers/date.helpers';

import { Submission } from '@shared/api/submission/submission.types';
import { RestBaseMapper } from '../base/rest-base.mapper';
import { FullPropertyPathOf, PropertyPathForNExtQueryEngine, RecursiveConcrete } from '@shared/types';

const propertyMapping: Map<string, string> = new Map();
propertyMapping.set('businessKey', 'businessKey');
propertyMapping.set('fachthemaBusinessKey', 'fachthema');
propertyMapping.set('prozessModus', 'processMode');
propertyMapping.set('dateiart', 'fileKind');
propertyMapping.set('datum', 'date');

@Injectable({
  providedIn: 'root',
})
export class SubmissionMapper extends RestBaseMapper<Submission, SubmissionGetDTO> {
  override toClientModel(dto: SubmissionGetDTO): Submission {
    return {
      id: dto.businessKey?.auftragId ?? '',
      correlationId: dto.correlationId ?? '',
      fileSizeByte: dto.dateiGroesseByte,
      hasUsedWebGui: dto.hatWebUiGenutzt,
      userId: dto.nutzerBusinessKey?.userId ?? '',
      isTechnicalUser: dto.istTechnischerNutzer,
      fileName: dto.dateiname,
      submissionTimestamp: dto.einreichungsZeitpunkt,
      businessTopicId: dto.fachthemaBusinessKey?.fKey,
      workAreaId: dto.arbeitsgebietBusinessKey?.arbeitsgebietKey ?? '',
      techSubmitterCompanyId: dto.techEinreicherUnternehmenBusinessKey?.nextId,
      techSubmitterCompanyIdentifierId: dto.techEinreicherFachidentifikatorBusinessKey?.key,
      fileKind: dto.dateiart,
      date: dto.datum,
      funcSubmitterCompanyId: dto.fachEinreicherId,
      processMode: dto.prozessModus,
      fileLocation: dto.fileLocation,
      deliveryEventDate: dto.zustellungEventDatum,
      readyForDelivery: dto.readyForZustellung,
      businessTopicCancelAssignment: dto.fachthemaZuordnungAbbruch,
      ...(dto.fachthemaZugeordnetDatum && dto.nutzerBusinessKeyFachthemaZugeordnet ?
        {
          businessTopicAssignment: {
            assignedBy: dto.nutzerBusinessKeyFachthemaZugeordnet.userId,
            assignedAt: dto.fachthemaZugeordnetDatum,
          },
        }
      : {}),
    };
  }

  override toDTO(data: Partial<Submission>) {
    const repositoryModel = {
      businessKey: { auftragId: data.id },
      fachthemaBusinessKey: { fKey: data.businessTopicId },
      datum: DateHelpers.dateStrIntoIsoDateStr(data.date!),
      dateiart: data.fileKind,
      prozessModus: data.processMode,
      fachthemaZuordnungAbbruch: data.businessTopicCancelAssignment,
    };

    return getCleanObject(repositoryModel);
  }

  override mapPropertyPathsFromClientToDTO(
    property: FullPropertyPathOf<Submission>
  ): PropertyPathForNExtQueryEngine<RecursiveConcrete<SubmissionGetDTO>> {
    switch (property) {
      case 'businessTopicCancelAssignment':
        return 'fachthemaZuordnungAbbruch';
      case 'businessTopicId':
        return 'fachthemaBusinessKey.fKey';
      case 'correlationId':
        return 'correlationId';
      case 'date':
        return 'datum';
      case 'deliveryEventDate':
        return 'zustellungEventDatum';
      case 'fileKind':
        return 'dateiart';
      case 'fileLocation':
        return 'fileLocation';
      case 'fileName':
        return 'dateiname';
      case 'fileSizeByte':
        return 'dateiGroesseByte';
      case 'funcSubmitterCompanyId':
        return 'fachEinreicherId';
      case 'hasUsedWebGui':
        return 'hatWebUiGenutzt';
      case 'id':
        return 'businessKey.auftragId';
      case 'isTechnicalUser':
        return 'istTechnischerNutzer';
      case 'processMode':
        return 'prozessModus';
      case 'readyForDelivery':
        return 'readyForZustellung';
      case 'submissionTimestamp':
        return 'einreichungsZeitpunkt';
      case 'techSubmitterCompanyId':
        return 'techEinreicherUnternehmenBusinessKey.nextId';
      case 'techSubmitterCompanyIdentifierId':
        return 'techEinreicherFachidentifikatorBusinessKey.key';
      case 'userId':
        return 'nutzerBusinessKey.userId';
      case 'workAreaId':
        return 'arbeitsgebietBusinessKey.arbeitsgebietKey';
      default:
        throw new Error(`Property ${property} not found in mapping.`);
    }
  }

  public static getPropertyName(propertyDtoName: string): string | undefined {
    return propertyMapping.get(propertyDtoName);
  }
}
